import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions, makeStyles, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import GenericInsert from '../GenericInsert';
import DynamicForm from '../DynamicForm';
import JSONTableEditor from '../JSONTableEditor';
import CentralCalls from '../../centralCalls';
import { useTheme } from '@material-ui/core/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
    customHeader: {
        borderRadius: '10px',
        fontSize: 'large',
        padding: '0px',
        margin: '0px',
        border: 'black solid'
    }
}))

const MaterialTemplatePreset = () => {
    const [templates, setTemplates] = useState(null);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [selectedTemplateOid, setSelectedTemplateOid] = useState(0);
    const handleTemplateChange = (event) => {
        //console.log(event)
        //console.log(event.target.value)
        setSelectedTemplateOid(event.target.value);
    };
    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('material_template').then(response => setTemplates(response));
    }, []);
    const handleAddTemplateClickPostRun = async (templateRow) => {
        if (templates) {
            const updatedBuilders = [...templates, { oid: templateRow.oid, template_name: templateRow.template_name }];
            setTemplates(updatedBuilders);
        }
        setOpenTemplate(false);
        setSelectedTemplateOid(templateRow.oid);
    }
    const handleSave = async (row) => { }
    const handleCompleteAddPresetPost = async (phaseRow) => { };
    //const deletePreset = async (row) => {console.log(row);}
    const procedureDetails = {
        procedureName: 'ui_sp_getTemplatePageData',
        parameters: { material_template_oid: selectedTemplateOid, crud_type : 'update' },
    };
    const procedureDetailsInsert = {
        procedureName: 'ui_sp_getTemplatePageDataInsert',
        parameters: { material_template_oid: selectedTemplateOid, crud_type : 'insert' },
    };
    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <h3>Template:
                            </h3>
                        </td>
                        <td style={{ paddingRight: '20px', paddingLeft: '10px' }}>
                            <Select
                                onChange={handleTemplateChange}
                                value={selectedTemplateOid ? selectedTemplateOid : 0}>
                                <MenuItem key={-1} value={0}>
                                    SELECT
                                </MenuItem>
                                {templates &&
                                    templates
                                        .slice()
                                        .sort((a, b) => a.template_name.localeCompare(b.template_name)) // Sort Builders inline
                                        .map((template) => (
                                            <MenuItem key={template.oid} value={template.oid}>
                                                {template.template_name}
                                            </MenuItem>
                                        ))}
                            </Select>
                            <Tooltip title="Add Template">
                                <IconButton color="primary" aria-label="save" onClick={() => setOpenTemplate(true)} size="medium">
                                    <FontAwesomeIcon icon={faPlus} />
                                </IconButton>
                            </Tooltip>
                        </td>

                    </tr>
                </tbody>
            </table>
            <br />
            <DynamicForm procedureDetails={procedureDetailsInsert}
                onSave={handleCompleteAddPresetPost}
                saveName='Insert'
                crudType='insert' />
            <JSONTableEditor procedureDetails={procedureDetails} onSave={handleSave} sortOrder={{ 'enabled': 'desc' }} allowDelete={true} ></JSONTableEditor>
            <Dialog fullWidth={true} maxWidth='lg' open={openTemplate} onClose={() => setOpenTemplate(false)}>
                <DialogContent>
                    <GenericInsert key={'/materialTemplate'} displayName={'Material Template'} tableName={'material_template'} onInsert={handleAddTemplateClickPostRun} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTemplate(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default MaterialTemplatePreset;