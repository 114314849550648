
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
// Define the component and destructure the prop from the component's props
const WipPOSection = ({ selectedLotOid }) => {
    const [total, setTotal] = useState(null);
    const [refreshIconKey, setRefreshIconKey] = useState(1000);
    const [addPODialogOpen, setAddPODialogOpen] = useState(false);
    //const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const onPoUpdate = (poRow) => {
        CentralCalls.upsertData({ 'oid': poRow.phase_oid, 'is_punch': poRow.is_punch ? 1 : 0 }, 'phase', 'update');
    }
    const addNewPoToPhase = async () => {
        setRefreshIconKey(prevKey => prevKey + 1);
        setAddPODialogOpen(false);
    }
    useEffect(() => {
        const getPoTotal = async () => {
            if (selectedLotOid) {
                var tot = await CentralCalls.callStoredProcedureWithParams('ui_sp_wipPoTotal', { 'lot_oid': selectedLotOid });
                setTotal(tot[0].total);
                //runPermitStatus();
            }
        }
        getPoTotal();
    }, [selectedLotOid]);
    const procedureAddPOByLotDetailsInsert = useMemo(() => ({
        procedureName: 'ui_sp_wipAddPoByPhase',
        parameters: { 'lot_oid': selectedLotOid, 'page_type': 'show_phase_insert' },  // If there are any parameters, define them here as an object
    }), [selectedLotOid]);
    const POProcedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_wipPoEdit',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
    }), [selectedLotOid]);
    return (
        <div>
            <h2>PO's ({CentralCalls.formatTotal(total)})
                {selectedLotOid && (
                    <Tooltip title="Add PO">
                        <IconButton color="primary" aria-label="save" onClick={() => setAddPODialogOpen(true)} size="medium">
                            <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                    </Tooltip>)}
            </h2>
            <JSONTableEditor key={refreshIconKey} allowDelete={true}
                procedureDetails={POProcedureDetails} onSave={onPoUpdate} sortOrder={{ 'date_billed': 'asc' }} />

            <Dialog fullWidth={true} maxWidth='lg' open={addPODialogOpen} onClose={() => setAddPODialogOpen(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent >
                    <h2>New PO</h2>
                    <DynamicForm procedureDetails={procedureAddPOByLotDetailsInsert}
                        onSave={addNewPoToPhase}
                        saveName='Add'
                        crudType='insert' ></DynamicForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPODialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default WipPOSection;