import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

function Logout() {
  //const history = useHistory();
  useEffect(() => {
    async function signOut() {
      try {
        await Auth.signOut();
        // Redirect or perform any other actions after successful logout
        // For example, you can use React Router to redirect to a specific route
        // window.location.href = '/login'; // Redirect to the login page
      } catch (error) {
        console.error('Error signing out', error);
      }
    }

    signOut();
  }, []);

  return <div>Logging out...</div>;
}

export default Logout;
