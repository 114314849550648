

import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
// Define the component and destructure the prop from the component's props
const WipInspectionSection = ({ selectedLotOid }) => {
    const [addPhaseDialogOpen, setAddPhaseDialogOpen] = useState(false);
    const [refreshPhaseKey, setRefreshPhaseKey] = useState(4000);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    //const [selectedPhaseRow, setSelectedPhaseRow] = useState(null);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const addNewPoToPhase = async () => {
        //await CentralCalls.upsertData({ 'phase_oid': selectedPhase}, 'po', 'insert');
        console.log('adding po phase...')
        //setRefreshIconKey(prevKey => prevKey + 1);
        setStatusDialogOpen(false);
        //setAddPODialogOpen(false);
      }
    const addPoClick = async (phaseRow) => {
        setSelectedPhase(phaseRow.phase_oid);
        setStatusDialogOpen(true);
        //setSelectedPhaseRow(phaseRow);
    }
    const handleSavePhaseDetails = async (slabRow) => {
        CentralCalls.upsertData({ 'oid': slabRow.phase_oid, 'date_scheduled': slabRow.date_scheduled, 'builder_date': slabRow.builder_date, 'is_punch': slabRow.is_punch ? 1 : 0 }, 'phase', 'update');
    };
    const addNewPhasePostSave = async (phaseRow) => {
        setAddPhaseDialogOpen(false);
        setRefreshPhaseKey(refreshPhaseKey + 1);
      }
      const procedureAddPOByPhaseDetailsInsert = useMemo(() => ({
        procedureName: 'ui_sp_wipAddPoByPhase',
        parameters: { 'phase_oid': selectedPhase, 'page_type': 'insert' },  // If there are any parameters, define them here as an object
      }), [selectedPhase]);
    const procedureAddPhaseDetails = useMemo(() => ({
        procedureName: 'ui_sp_wipInsertPunchPhase',
        parameters: { lot_oid: selectedLotOid },  // If there are any parameters, define them here as an object
      }), [selectedLotOid]);
    const slabProcedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_getLotOverview',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
    }), [selectedLotOid]);
    const customFunctionsWipPhases = [
        {
            display_name: 'Add PO',
            type: 'money', // type must correspond to a FontAwesome icon name
            func: addPoClick,
            alwaysEnable: true
        }
    ]
    return (
        <>
            <h2>Phases
                <Tooltip title="Add New Task To Phase">
                    <IconButton color="primary" aria-label="save" onClick={() => setAddPhaseDialogOpen(true)} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h2>
            <JSONTableEditor
                key={refreshPhaseKey}
                customFunctions={customFunctionsWipPhases}
                procedureDetails={slabProcedureDetails}
                onSave={handleSavePhaseDetails}
                sortOrder={{
                    'is_cancelled': 'asc',
                    'status_date': 'asc',
                    'phase_order': 'asc'
                }} />

            <Dialog fullWidth={true} maxWidth='lg' open={addPhaseDialogOpen} onClose={() => setAddPhaseDialogOpen(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent >
                    <h2>New Phase</h2>
                    <DynamicForm procedureDetails={procedureAddPhaseDetails}
                        onSave={addNewPhasePostSave}
                        saveName='Add'
                        crudType='insert' ></DynamicForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPhaseDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} maxWidth='lg' open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>New Po</h2>
                        <DynamicForm procedureDetails={procedureAddPOByPhaseDetailsInsert}
                            onSave={addNewPoToPhase}
                            saveName='Add'
                            crudType='insert' ></DynamicForm>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>


    );
}

export default WipInspectionSection;