import React, { useState, useEffect } from 'react';
import './ChartLayout.scss';
import ReChart from '../report/ReChart';
import TestChart from '../report/TestChart'
import GridCalculator from '../../services/GridCalculator';
//import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle, FiArrowUpCircle, FiArrowDown, FiArrowDownCircle } from "react-icons/fi";

const ChartLayout = () => {
    const [params, setParams] = useState({selected_crypto_id : 201, detectChange : false});
    const [pageData, setPageData] = useState(null);
  useEffect(() => {
    // Fetch the data from an API or set it through other mechanisms.
    // For example, you can fetch data using Axios or the native Fetch API here.
    // Once you have the data, set it using setPageData.

    // For now, let's just simulate a delay to show the loading state.
    const fetchData = () => {
      setTimeout(() => {
        setPageData({
          columns: 3,
          rows: 5,
          charts: [
             { typ: 'chart', chartType: 'table', id: 1, height: 1, width: 3, row:1, col:1, spName : 'cht_getBasicOverView'}
            ,{ typ: 'chart', chartType: 'table', id: 1, height: 2, width: 2, row:2, col:1, spName : 'cht_getWorkerUndergroundEffectiveness'}
            ,{ typ: 'chart2',chartType: 'line',id: 2, height:1, width:1, row:2, col:3, spName : 'cht_getWorkerPhaseAverages' }
            ,{ typ: 'chart', chartType: 'table',id: 3, height:1, width:1, row:3, col:3, spName : 'cht_getAverageHoursByPhase' }
            ,{ typ: 'chart2',chartType: 'line',id: 4, height: 1, width: 1, row:4, col:1, spName : 'charts.crypto_history'}  
            ,{ typ: 'chart', chartType: 'table',id: 5, height: 2, width: 2, row:4, col:2, spName : 'cht_getWorkerPhaseAverages2'}
            ,{ typ: 'chart2',chartType: 'bar',id: 6, height: 1, width: 1, row:5, col:1, spName : 'charts.crypto_history'}
        ]
        });
      }, 2000); // Simulate a 2-second delay for demonstration purposes.
    };

    fetchData();
  }, []);

    const calcWidth = (width) => {
      let myWidth = (width * 240)
      if(pageData.isCollapsed){      
        myWidth = myWidth + (width * 80)
        return myWidth;
      }else{
        return myWidth;
      }
    }
    const ViewType = (typ, spName, height, chartType, chtData) => {   
      let myHeight = height * 100 + ((height * 70) - 80);
      switch(typ){
        case "chart2":
          return <TestChart key={params.detectChange} chtData={chtData} myHeight={myHeight} spName={spName} params={params} type={chartType} />;
        case "chart":
          return <ReChart key={params.detectChange} chtData={chtData}  myHeight={myHeight} spName={spName} params={params} type={chartType} />;
        case "pagebuilder": 
          return <TestChart key={params.detectChange} chtData={chtData}  myHeight={myHeight} spName={spName} params={params} type={chartType}  />
        default:
          return "Build Item Not Found:" + typ;
      }
    }
    
  if (!pageData) {
    return <div>Loading...</div>;
  }
    const root = document.documentElement;
    let grid = GridCalculator.CreateGrid(pageData.charts, pageData.rows, pageData.columns);
    let chartNum = 0;
    root.style.setProperty("--back-color", "white");
    root.style.setProperty("--item-color", "whitesmoke");
    root.style.setProperty("--grid-text", grid);
    if (pageData.typ === "single") {
      return (
        <div key={1} style={{  overflow:'hidden', margin:'10px', padding: '10px',  width:'98%', minHeight:'500px' }}>
                  {ViewType(pageData.charts[0].typ, pageData.charts[0].spName, 100, pageData.charts[0].height, pageData.charts[0].chartType, pageData.charts[0])}  
        </div>    
      );
    } else {
      return (
        <>
       <h2>Reports</h2>
        <div className="container" >
          
          {pageData.charts.map(cht => {
            chartNum++;
            let myWidth = calcWidth(cht.width); //console.log('adding with:' + (width * 90));
            return (
              <div key={chartNum}  className={"col"+ chartNum + " card"} >
                  {/* /{cht.header ? <h2>{cht.header}</h2>  : null} */}
                  {ViewType(cht.typ,cht.spName, cht.height, cht.chartType, cht)}
              </div> ); })}
        </div>
        </>
      )}
}
export default ChartLayout;
