import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, makeStyles, Collapse, ListItemIcon, Grid, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PhaseEdit from './components/phase/phase';
import AllPhase from './components/phase/allPhase';
import BasePhase from './components/phase/parts/basePhase';
import GenericEdit from './components/GenericEdit';
import GenericInsert from './components/GenericInsert';
import TopMenu from './components/TopMenu';
import SideMenu from './components/SideMenu';
import ChartLayout from './components/report/ChartLayout';
import Export from './components/report/Export';
import LotView from './components/workInProgress/LotView';
import WipPage from './components/workInProgress/WipPage';
import GenericSelectEdit from './components/GenericSelectEdit';
import Permit from './components/phase/permit';
import Pos from './components/phase/pos';
import Lot from './components/phase/lot';
import Logout from './components/Logout';
import MaterialTemplatePreset from './components/material/MaterialPreset';
import PrintMaterials from './components/schedule/PrintMaterials';
//import Amplify from 'aws-amplify';
import About from './components/About';
import awsconfig from './aws-exports';
import { Auth } from 'aws-amplify';
import DailySchedule from './components/schedule/DailySchedule';
import EmployeeSchedule from './components/schedule/EmployeeSchedule';
import PieceWork from './components/schedule/PieceWork';
import PrintPage from './components/schedule/PrintPage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import TaskList from './components/TaskList';
import '@aws-amplify/ui/dist/styles.css';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import MaterialSelector from './components/workInProgress/MaterialSelector';
import PhasePage from './components/phase/phasePage';
//Auth.configure(awsconfig);
//Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#B3CDE6',
    color: 'black'
  },
  drawer: {
    width: 240,
  },
  drawerPaper: {
    width: 240,
    marginTop: 64,
  },
  content: {
    flexGrow: 1,
    marginLeft: 240,
    padding: 16,
    paddingTop: 65
  },
  contentFullWidth: {
    flexGrow: 1,
    marginLeft: 0,
    padding: 16,
    paddingTop: 65
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  sideMenu: {
    backgroundColor: '#E5F0F9'
  },
  drawerPaper: {
    width: 300,
    marginTop: 64,
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    width: theme.spacing(7) + 1, // Same width as sidebar icons
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#0B426B',
    },
    secondary: {
      main: '#2B87C8',
    },
  },
});

function App() {
  const classes = useStyles();
  const [insertTrigger, setInsertTrigger] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const getUserAttributes = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =user.signInUserSession.accessToken.payload['cognito:groups'];
      if(groups.includes('full_access')){
        console.log('setting full access')
        localStorage.setItem('access', 'full');
      }else{
        localStorage.setItem('access', 'limited');
      }
      localStorage.setItem('user_name', user.attributes.name);
      const attributes = user.attributes;
      const firstName = attributes['name'];
    } catch (error) {
      console.error('Error fetching user attributes', error);
    }
  };

  useEffect(() => {
    getUserAttributes();
  }, []);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleInsert = (row) => {
    setInsertTrigger(!insertTrigger);
  };
  
  const jsonData = [
    { name: 'John Doe', age: 30, email: 'johndoe@example.com' },
    { name: 'Jane Smith', age: 25, email: 'janesmith@example.com' },
    { name: 'Bob Johnson', age: 35, email: 'bobjohnson@example.com' },
  ];
  return (
    <Router>
      <TopMenu sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen} />
      <SideMenu sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen} />
      <div className={sideMenuOpen ? classes.content : `${classes.content} ${classes.contentFullWidth}`}>
        <Routes>
          {/* <Route path="/" element={<WipPage data={jsonData} />} /> */}
          <Route path="/" element={<About />} />
          <Route path="/about" element={<About />} />
          <Route path="/lot" element={<><GenericInsert key={'/lotIn'} tableName={'lot'} onInsert={handleInsert} /><br /><GenericEdit key={'/lot'} tableName={'lot'} onInsert={insertTrigger} /></>} />
          <Route path="/bid" element={<><GenericInsert key={'/bidIn'} tableName={'bid'} onInsert={handleInsert} /><br /><GenericEdit key={'/bid'} tableName={'bid'} onInsert={insertTrigger} /></>} />
          <Route path="/neighborhood" element={<><GenericInsert key={'/neighborhoodIn'} tableName={'neighborhood'} onInsert={handleInsert} /><br /><GenericEdit key={'/neighborhood'} tableName={'neighborhood'} onInsert={insertTrigger} /></>} />
          <Route path="/builder" element={<><GenericInsert key={'/builderIn'} tableName={'builder'} onInsert={handleInsert} /><br /><GenericEdit key={'/builder'} tableName={'builder'} onInsert={insertTrigger} /></>} />
          <Route path="/material" element={<><GenericInsert key={'/materialIn'} tableName={'material'} onInsert={handleInsert} /><br /><GenericEdit key={'/material'} tableName={'material'} onInsert={insertTrigger} /></>} />
          <Route path="/municipality" element={<><GenericInsert key={'/municipalityIn'} tableName={'municipality'} onInsert={handleInsert} /><br /><GenericEdit key={'/municipality'} tableName={'municipality'} onInsert={insertTrigger} /></>} />
          <Route path="/district" element={<><GenericInsert key={'/districtIn'} tableName={'district'} onInsert={handleInsert} /><br /><GenericEdit key={'/district'} tableName={'district'} onInsert={insertTrigger} /></>} />
          <Route path="/employee" element={<><GenericInsert key={'/employeeIn'} tableName={'employee'} onInsert={handleInsert} /><br /><GenericEdit key={'/employee'} tableName={'employee'} onInsert={insertTrigger} /></>} />
          <Route path="/plan_number" element={<><GenericInsert key={'/plan_numberIn'} tableName={'plan_number'} onInsert={handleInsert} /><br /><GenericEdit key={'/plan_number'} tableName={'plan_number'} onInsert={insertTrigger} /></>} />
          <Route path="/material_room" element={<><GenericInsert key={'/materialRoom'} tableName={'material_room'} onInsert={handleInsert} /><br /><GenericEdit key={'/material_roomE'} tableName={'material_room'} onInsert={insertTrigger} /></>} />
          <Route path="/material_preset" element={<><GenericInsert key={'/materialPreset'} tableName={'material_preset'} onInsert={handleInsert} /><br /><GenericEdit key={'/material_preset'} tableName={'material_preset'} onInsert={insertTrigger} /></>} />
          <Route path="/material_model" element={<><GenericInsert key={'/materialModel'} tableName={'material_model'} onInsert={handleInsert} /><br /><GenericEdit key={'/material_model'} tableName={'material_model'} onInsert={insertTrigger} /></>} />
          <Route path="/material_templates" element={<><GenericInsert key={'materialTemplates'} tableName={'material_template'} onInsert={handleInsert} ></GenericInsert> <br /><GenericEdit key={'/material_templatesEdit'} tableName={'material_template'} onInsert={insertTrigger} /> </>} />
          <Route path="/material_template" element={<MaterialTemplatePreset key={'materialTemplate'} ></MaterialTemplatePreset>} />

          <Route path="/phase" element={<PhasePage phaseType={'slab'} />} />
          <Route path="/wip" element={<WipPage data={jsonData} />} />
          <Route path="/phase/slab" element={<AllPhase phaseType={'slab'} />} />
          <Route path="/phase/slab_qc" element={<AllPhase phaseType={'slab_qc'} />} />
          <Route path="/phase/slab_inspection" element={<AllPhase phaseType={'slab_inspection'} />} />
          <Route path="/phase/underground" element={<AllPhase phaseType={'underground'} />} />
          {/* <Route path="/phase/underground_inspection" element={<AllPhase phaseType={'underground_inspection'} />} /> */}
          <Route path="/phase/rough" element={<AllPhase phaseType={'rough'} />} />
          <Route path="/phase/rough_qc" element={<AllPhase phaseType={'rough_qc'} />} />
          {/* <Route path="/phase/rough_inspection" element={<AllPhase phaseType={'rough_inspection'} />} /> */}
          <Route path="/phase/draintubs" element={<AllPhase phaseType={'draintubs'} />} />
          <Route path="/phase/setout" element={<AllPhase phaseType={'setout'} />} />
          <Route path="/phase/setout_qc" element={<AllPhase phaseType={'setout_qc'} />} />
          {/* <Route path="/phase/setout_inspection" element={<AllPhase phaseType={'setout_inspection'} />} /> */}
          <Route path="/phase/warranty" element={<AllPhase phaseType={'warranty'} />} />
          <Route path="/charts" element={<ChartLayout />} />
          <Route path="/phase/draintubs" element={<PhaseEdit phaseType={'Drain Tubs'} phaseTypeOid={11} />} />
          <Route path="/phase/setout" element={<PhaseEdit phaseType={'Set-Out'} phaseTypeOid={12} />} />
          <Route path="/phase/underground" element={<PhaseEdit phaseType={'Underground'} phaseTypeOid={13} />} />
          <Route path="/phase/sewer" element={<PhaseEdit phaseType={'Sewer'} phaseTypeOid={14} />} />
          {/* <Route path="/wip" element={<LotView data={jsonData}></LotView>} /> */}

          <Route path="/phaseAll" element={<BasePhase phaseType={'slab'}></BasePhase>} />

          <Route path="/export" element={<Export></Export>} />
          <Route path="/tasklist" element={<TaskList></TaskList>} />
          <Route path="/dailySchedule" element={<DailySchedule></DailySchedule>} />
          <Route path="/printMaterials" element={<PrintMaterials></PrintMaterials>} />
          <Route path="/employeeSchedule" element={<EmployeeSchedule></EmployeeSchedule>} />
          <Route path="/piece_work" element={<PieceWork page_type={'piece_work'}></PieceWork>} />
          <Route path="/schedule" element={<PieceWork page_type={'schedule'}></PieceWork>} />
          <Route path='/phase/permit' element={<Permit  ></Permit>}></Route>
          <Route path='/phase/po' element={<Pos ></Pos>}></Route>
          <Route path='/printSchedule' element={<PrintPage ></PrintPage>}></Route>
          <Route path='/logout' element={<Logout ></Logout>}></Route>
          <Route path='/phase/lot' element={<Lot ></Lot>}></Route>
          <Route path="/gen/edit" element={<GenericEdit tableName="builder"
            columns={[
              {
                name: 'oid',
                type: 'number',
                controlType: 'textbox',
                required: true,
              },
              {
                name: 'name',
                display_name: 'Name',
                type: 'string',
                controlType: 'textbox',
                required: true,
              },
              {
                name: 'role_oid',
                display_name: 'Role',
                type: 'number',
                controlType: 'list',
                required: true,
                options: [{ id: 3, name: 'Administrator' },
                { id: 1, name: 'User' },
                { id: 2, name: 'Guest' }
                ],
              },
            ]}
          />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;