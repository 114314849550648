import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import '../../components/styles.css';
import GenericEdit from '../GenericEdit';
import GenericInsert from '../GenericInsert'
const PhaseEdit = (props) => {
    const [editingRow, setEditingRow] = useState(-1);
    const [formData, setFormData] = useState({
        work_type_oid: null,
        date_entered: null,
        lot_oid: null,
        date_ready: null,
        date_scheduled: null,
        plumber_oid: null,
        super_oid: null,
        complete: null,
        is_punch: null,
        failed_warranty: null
    });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [phaseToDelete, setPhaseToDelete] = useState(-1);
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);

    const fetchPhases = () => {
        fetch('/api/call-stored-procedureWithParam', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                procedureName: 'SelectPhasesByPhaseType',
                phase_type_oid: props.phaseTypeOid
            }),
        })
            .then((response) => {
                console.log('fetch phase');
                return response.json();
            })
            .then((fetchedData) => {
                setPhases(fetchedData);
            })
            .catch((error) => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchPhases();
    }, [props.onInsert]);

    const handleEdit = (phase) => {
        setEditingRow(phase.oid);
        setFormData(phase);
    };
    const [currentPhase, setCurrentPhase] = useState({});
    const handleSave = async () => {
        fetch('/api/upsert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName: 'phase_view', changeType: 'update', ID: editingRow, jsonObj: formData }),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setFormData({
                    work_type_oid: null,
                    date_entered: null,
                    lot_oid: null,
                    date_ready: null,
                    date_scheduled: null,
                    plumber_oid: null,
                    super_oid: null,
                    complete: null,
                    is_punch: null,
                    failed_warranty: null
                });
                setEditingRow(-1);
                fetchPhases(); // Refresh the phases after saving
            })
            .catch((error) => console.error('Error adding or updating phase:', error));
    };

    const handleDelete = async () => {
        fetch('/api/upsert', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tableName: 'phase_view', changeType: 'delete', ID: phaseToDelete }),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                handleCloseDeleteDialog();
                fetchPhases(); // Refresh the phases after deleting
            })
            .catch((error) => console.error('Error deleting phase:', error));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleClickOpenDeleteDialog = (phaseOid) => {
        setPhaseToDelete(phaseOid);
        setDeleteDialogOpen(true);
    };
    const handleCloseDeleteDialog = () => {
        setPhaseToDelete(-1);
        setDeleteDialogOpen(false);
    };
    const [phases, setPhases] = useState([]);

    return (
        <>
            <Typography variant="h6" >{props.phaseType} - {props.phaseType}</Typography>
            <Table className="edit-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Work Type OID</TableCell>
                        <TableCell>Date Entered</TableCell>
                        <TableCell>Lot OID</TableCell>
                        <TableCell>Date Ready</TableCell>
                        <TableCell>Date Scheduled</TableCell>
                        <TableCell>Plumber OID</TableCell>
                        <TableCell>Super OID</TableCell>
                        <TableCell>Complete</TableCell>
                        <TableCell>Is Punch</TableCell>
                        <TableCell>Failed Warranty</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {phases.map((phase) => (
                        <React.Fragment key={phase.oid}>
                            {editingRow !== phase.oid && (
                                <TableRow key={phase.oid}>
                                    <TableCell>{phase.work_type_oid}</TableCell>
                                    <TableCell>{phase.date_entered}</TableCell>
                                    <TableCell>{phase.lot_oid}</TableCell>
                                    <TableCell>{phase.date_ready}</TableCell>
                                    <TableCell>{phase.date_scheduled}</TableCell>
                                    <TableCell>{phase.plumber_oid}</TableCell>
                                    <TableCell>{phase.super_oid}</TableCell>
                                    <TableCell>{phase.complete ? 'True' : 'False'}</TableCell>
                                    <TableCell>{phase.is_punch ? 'True' : 'False'}</TableCell>
                                    <TableCell>{phase.failed_warranty ? 'True' : 'False'}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(phase)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleClickOpenDeleteDialog(phase.oid)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            setCurrentPhase(phase);
                                            setStatusDialogOpen(true);
                                        }}>
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                            {editingRow === phase.oid && (
                                <TableRow>
                                    {Object.keys(phase).map((key) => (
                                        <TableCell key={key}>
                                            <TextField
                                                name={key}
                                                value={formData[key]}
                                                onChange={handleChange}
                                                type={key === 'oid' ? 'number' : 'text'}
                                                InputProps={{
                                                    readOnly: key === 'oid',
                                                }}
                                            />
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <IconButton onClick={handleSave}>
                                            <SaveIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table >
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Phase"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this phase?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogTitle>Add new phase</DialogTitle>
                <DialogContent >
                    {/* Replace with your actual form */}
                    <>
                    {currentPhase && (
                        <GenericInsert tableName={'phase_status'} whereClause={{ work_phase_oid: currentPhase.oid }}></GenericInsert>
                    )}
                        {currentPhase && (
                            <GenericEdit
                                tableName={'phase_status'}
                                whereClause={{ work_phase_oid: currentPhase.oid }}
                            />
                        )}
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
};

export default PhaseEdit;

