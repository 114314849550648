import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '././../plumbingLogo.png';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between', // Align items horizontally
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#B3CDE6',
    color: 'black',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: 45,
    width: 60,
  },
  logoutLink: {
    marginLeft: 'auto', // Pushes the link to the right
  },
}));

const TopMenu = ({ sideMenuOpen, setSideMenuOpen }) => {
  const classes = useStyles();
  //const [userName, setUserName] = React.useState('');
  const handleSideMenuToggle = () => {
    setSideMenuOpen(!sideMenuOpen);
  };
  useEffect(() => {
    async function setUserInfo() {
      try {
        //const user = await Auth.currentAuthenticatedUser();
        //console.log(user);
        //setUserName(user.attributes.email);
      } catch (error) {
        console.error('Error fetching user info', error);
      }
    }    
    setUserInfo();
  }, []); // The empty dependency array means this effect runs once on mount
  async function Logout() {
    try {
      await Auth.signOut();
      // Redirect or perform any other actions after successful logout
      // For example, you can use React Router to redirect to a specific route
      // window.location.href = '/login'; // Redirect to the login page
    } catch (error) {
      console.error('Error signing out', error);
    }
  }
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.root}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={handleSideMenuToggle}
        >
          <MenuIcon />
        </IconButton>
       
        <img src={logo} alt="Logo" className={classes.logo} />
        <span style={{marginLeft:'55px'}}></span>
        <h2>Plumbing Operations</h2>
        <div className={classes.logoutLink}>
          Welcome Guest<br/>
          <Link onClick={() => Logout()}>Logout</Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
