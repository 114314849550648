import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../../JSONTableEditor';
import DynamicForm from '../../DynamicForm';
import CentralCalls from '../../../centralCalls';
//import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, makeStyles, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../../workInProgress/LotFilter';
import ActivePhaseButtonGroup from '../activePhaseButtonGroup';
const ActivePhase = ({ phaseType, handleWipLinkClick}) => {
    const [refresh, setRefresh] = useState(false);
    const [PhaseOid, setPhaseOid] = useState(null);
    //const [phaseName, setPhaseName] = useState('');
    //const [addQCDialogOpen, setAddQCDialogOpen] = useState(false);
    //const [qcTitle, setQcTitle] = useState('UNSET');
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const [LotOid, setLotOid] = useState(null);
    const [UndergroundDialogOpen, setUndergroundDialogOpen] = useState(false);
    const [lotTitle, setLotTitle] = useState('not title');
    const [isQc, setIsQc] = useState(0);
    //const [isInspection, setIsInspection] = useState(0);
    const [PunchDialogOpen, setPunchDialogOpen] = useState(false);
    const [punchLotOid, setPunchLotOid] = useState(null);
    const [qcPhaseOptions, setQcPhaseOptions] = useState([]);
    const [selectedPhaseForQC, setSelectedPhaseForQC] = useState(0);
    const [selectedSecondaryPhaseForQC, setSelectedSecondaryPhaseForQC] = useState('slab_inspection');
    const [secondaryInspectionLotOid, setSecondaryInspectionLotOid] = useState(0);
    const [NewQCLotOid, setNewQCLotOid] = useState(0);
    const [AddQCValid, setAddQCValid] = useState(null);
    const [refreshUndergroundKey, setRefreshUndergroundKey] = useState(0);

    //const navigate = useNavigate(); 
    useEffect(() => {
        const getLotTitle = async () => {
            const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: LotOid });
            setLotTitle(title.title);
        }
        getLotTitle();
    }, [LotOid]);

    const procedureDetails = {
        procedureName: 'ui_sp_getPhasePageData',
        parameters: { phase_type: phaseType, page_type: 'current' },  // If there are any parameters, define them here as an object
    };
    const handleSave = async (phaseRow) => {
        CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'date_scheduled': phaseRow.date_scheduled, 'builder_date': phaseRow.builder_date, 'is_punch': phaseRow.is_punch ? 1 : 0 }, 'phase', 'update');
    };
    const checkDisplayComplete = (phaseRow) => {
        if ((phaseRow.date_scheduled && phaseRow.date_scheduled !== null && phaseRow.employee_oids && phaseRow.employee_oids !== '[]') || (phaseRow.is_inspection && phaseRow.date_scheduled && phaseRow.date_scheduled !== null)) { return true; } else { return false; }
    }
    const checkDisplayUndergroundApproval = (phaseRow) => {
        if (phaseType === 'underground') { return true; } else { return false; }
    }
    const checkDisplayNonComplete = (phaseRow) => {
        if (phaseRow.is_single_status === 0 && phaseRow.is_qc !== 1 && phaseRow.is_inspection !== 1) { return true; } else { return false; }
    }
    const checkDisplayOnHold = (phaseRow) => {
        return true;
        // if (phaseType === 'underground') { return true } else { return false; }
    }
    const handleCancelClick = async (phaseRow) => {
        await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'cancel', 'status_date': new Date() }, 'phase_status', 'update');
        setRefresh(new Date().getTime());
    }
    const handleRevertNonCompleteRecentClick = async (phaseRow) => {
        await CentralCalls.callStoredProcedureWithParams('revert_phase_status', { 'phase_oid': phaseRow.phase_oid });
        setRefresh(new Date().getTime());
    }
    //   const handleWipLinkClick = async (phaseRow) => {
    //     const path = '/wip?lot_oid=' + phaseRow.lot_oid;
    //     navigate(path);
    //   }
    // const handleSelectedSecondaryPhaseForQCChange = async (event) => {
    //     const phase_type = event.target.value;
    //     if (isInspection && selectedSecondaryPhaseForQC === 0) {
    //         setAddQCValid(true);
    //     }
    //     await setSelectedSecondaryPhaseForQC(phase_type);
    //     setQCPhaseOptions(secondaryInspectionLotOid, phase_type);
    // }
    const handleCompletePhase = async (phaseRow) => {
        setStatusDialogOpen(true);
        setLotOid(phaseRow.lot_oid);
        setPhaseOid(phaseRow.phase_oid);
    };
    // const handleAddPunchClick = async () => {
    //     const newPhase = await CentralCalls.upsertData({ 'lot_oid': punchLotOid, 'phase_type': phaseType, 'is_punch': 1 }, 'phase', 'insert');
    //     await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
    //     closePunchDialog();
    // }
    const handleSaveUndergroundHistory = async (phaseRow) => { }
    const handleDisplayUndergroundApprovalClick = async (phaseRow) => {
        setRefresh(new Date().getTime());
        setPhaseOid(phaseRow.phase_oid);
        setLotOid(phaseRow.lot_oid);
        setUndergroundDialogOpen(true);
    }
    // const handleCompletephaseClose = async (phaseRow) => {
    //     setStatusDialogOpen(false);
    //     setPhaseOid(phaseRow.phase_oid);
    //     if (phaseRow.phase_type === 'slab') {
    //         const updateObj = {
    //             oid: LotOid,
    //             sewer_location: phaseRow.sewer_location,
    //             water_location: phaseRow.water_location,
    //         };
    //         try {
    //             const response = await fetch('/api/update', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify({ tableName: 'lot', updateObj: updateObj }),
    //             });
    //             const data = await response.json();
    //         } catch (err) {
    //             console.error(err);
    //             // Handle error
    //         }
    //     }
    //     if (phaseRow.finished === 1 && (phaseRow.status === 'fail')) {
    //         const insertObj = {
    //             phase_oid: phaseRow.phase_oid,
    //             comments: phaseRow.comments,
    //             employee_oids: JSON.stringify(phaseRow.employee_oids)
    //         };
    //         await CentralCalls.upsertData(insertObj, 'phase_status', 'insert');
    //         setRefresh(new Date().getTime());
    //     }
    //     if (isQc === 1 || isInspection === 1) {
    //         CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'qc_status': phaseRow.qc_status }, 'phase', 'update');
    //     }
    //     if ((isQc === 1 || isInspection === 1) && phaseRow.qc_status === 'fail') {
    //         failedQCAutoPunch(phaseRow);
    //     }
    // };
    const handleCompleteUndergroundClose = async (undergroundRow) => {
        setUndergroundDialogOpen(false);
    }
    // const handleSavePhaseSaveEdit = async (phaseRow) => {

    // }
    // const handleLotSelected = async (lot_oid) => {
    //     setPunchLotOid(lot_oid);
    // }
    // const handleSelectedPhaseForQCChange = async (event) => {
    //     const oid = event.target.value;
    //     if (oid !== 0) {
    //         setAddQCValid(true);
    //     }
    //     else {

    //     }
    //     setSelectedPhaseForQC(oid);
    // }
    // const failedQCAutoPunch = async (phaseRow) => {
    //     const punchPhaseType = phaseType.split('_')[0];
    //     const newPhase = await CentralCalls.upsertData({ 'lot_oid': LotOid, 'phase_type': punchPhaseType, 'is_punch': 1, 'punch_comments': phaseRow.comments, 'builder_date': phaseRow.date_scheduled }, 'phase', 'insert');
    //     await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
    // }
    // const onFilterSelect = (filter_name_id) => {
    //     console.log(filter_name_id);
    // }
    // const setQCPhaseOptions = async (selected_lot_oid, secondary_phase_type) => {
    //     console.log('setting qc phase options...' + selected_lot_oid);
    //     const qcForPhaseType = phaseType.split('_')[0];
    //     var this_phase_type = qcForPhaseType;
    //     if (isInspection === 1) {
    //         console.log(secondary_phase_type.split('_')[0])
    //         this_phase_type = secondary_phase_type.split('_')[0];
    //     }
    //     //console.log({ lot_oid: parseInt(lot), phase_type: this_phase_type, is_inspection : isInspection, inspection_phase_type : selectedSecondaryPhaseForQC })
    //     const result = await CentralCalls.callStoredProcedureWithParams('sp_checkLotExistingQC', { lot_oid: parseInt(selected_lot_oid), phase_type: this_phase_type, is_inspection: isInspection, inspection_phase_type: selectedSecondaryPhaseForQC });
    //     if (!result || result.length === 0) {
    //         setQcPhaseOptions([], selectedSecondaryPhaseForQC);
    //         //CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
    //         //setAddQCValid(false);         
    //     } else {
    //         setNewQCLotOid(selected_lot_oid);
    //         //setAddQCValid(true);
    //         setQcPhaseOptions(result, selectedSecondaryPhaseForQC);
    //     }
    // }
    // const OpenQCDialogOpen = async () => {
    //     setAddQCDialogOpen(true);
    //     setQcPhaseOptions([]);
    //     setSelectedPhaseForQC(0);
    //     setAddQCValid(false);
    // }
    // const closePunchDialog = async () => {
    //     setPunchDialogOpen(false);
    //     setPunchLotOid(null);
    // }
    // const addPunch = async () => {
    //     setPunchDialogOpen(true);
    // }
    // const confirmLotQCAddOption = async (lot) => {
    //     setQCPhaseOptions(lot, selectedSecondaryPhaseForQC);
    //     setSecondaryInspectionLotOid(lot);
    // }
    // const insertNewQC = async () => {
    //     //const qcForPhaseType = phaseType.split('_')[0];
    //     var phase_type_now = phaseType;
    //     if (isInspection) {
    //         phase_type_now = selectedSecondaryPhaseForQC;
    //     }
    //     const phaseRow = await CentralCalls.upsertData({ lot_oid: NewQCLotOid, phase_type: phase_type_now, qc_phase_oid: selectedPhaseForQC }, 'phase', 'insert');
    //     await CentralCalls.upsertData({ 'phase_oid': phaseRow.oid }, 'phase_status', 'insert');
    //     //const nonQCPhase = await CentralCalls.fetchTableData('phase', { lot_oid: NewQCLotOid, phase_type: phaseType, });
    //     //await CentralCalls.upsertData({ oid: selectedPhaseForQC, qc_phase_oid : phaseRow.oid }, 'phase', 'update');
    //     setAddQCDialogOpen(false);
    //     //setRefreshKeyOpen(refreshKeyOpen +1);
    // }
    const handlePutOnHold = async (phaseRow) => {
        await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'hold', 'status_date': new Date() }, 'phase_status', 'update');
        setRefresh(new Date().getTime());
    }
    const procedureDetailsUndergroundTickets = {
        procedureName: 'ui_getUndergroundTickets',
        parameters: { phase_oid: PhaseOid, lot_oid: LotOid }
    }
    // const procedureDetailsCompletion = {
    //     procedureName: 'ui_sp_getPhaseCompletionPageData',
    //     parameters: { phase_oid: PhaseOid, lot_oid: LotOid },  // If there are any parameters, define them here as an object
    // };
    // const procedureDetailsCompletionInsert = {
    //     procedureName: 'ui_sp_getPhaseCompletionPageDataInsert',
    //     parameters: { phase_oid: PhaseOid, phase_type: phaseType, lot_oid: LotOid },  // If there are any parameters, define them here as an object
    // };
    const procedureDetailsUndergroundHistory = {
        procedureName: 'ui_sp_wipUndergroundTickets',
        parameters: { lot_oid: LotOid },
    }
    const customFunctions = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleCompletePhase,
            displayCheck: checkDisplayComplete
        },
        {
            display_name: 'Revert',
            type: 'revert', // type must correspond to a FontAwesome icon name
            func: handleRevertNonCompleteRecentClick,
            displayCheck: checkDisplayNonComplete
        },
        {
            display_name: 'Underground Ticket',
            type: 'approval', // type must correspond to a FontAwesome icon name
            func: handleDisplayUndergroundApprovalClick,
            displayCheck: checkDisplayUndergroundApproval
        },
        {
            display_name: 'Hold',
            type: 'pause', // type must correspond to a FontAwesome icon name
            func: handlePutOnHold,
            displayCheck: checkDisplayOnHold
        },
        {
            display_name: 'Cancel',
            type: 'cancel',
            func: handleCancelClick
        },
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
        }
    ]
    return (
        <>
            {/* <h2> {phaseName === 'Slab Inspection' ? 'Inspections' : phaseName}
                {isQc !== 1 && (
                    <Tooltip title="Add Punch">
                        <IconButton color="primary" aria-label="save" onClick={addPunch} size="medium">
                            <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                    </Tooltip>
                )}
                {isQc === 1 && (
                    <Tooltip title={qcTitle}>
                        <IconButton color="primary" aria-label="save" onClick={() => { OpenQCDialogOpen() }} size="medium">
                            <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                    </Tooltip>
                )}
            </h2> */}
            {/* <ActivePhaseButtonGroup onFilterSelect={onFilterSelect}  ></ActivePhaseButtonGroup> */}

            <JSONTableEditor
                procedureDetails={procedureDetails}
                onSave={handleSave}
                sortOrder={{
                    'enabled': 'desc',
                    'date_scheduled': 'asc',
                    'builder_date': 'asc',
                    'builder': 'asc',
                    'neighborhood': 'asc',
                    'lot_num': 'asc',
                    'phase_for_inspection': 'asc'
                }}
                customFunctions={customFunctions} />
            <Dialog fullWidth={true} maxWidth='lg' open={UndergroundDialogOpen} onClose={() => setUndergroundDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>Tickets</h2>
                        <JSONTableEditor
                            key={refreshUndergroundKey + 1}
                            procedureDetails={procedureDetailsUndergroundHistory}
                            onSave={handleSaveUndergroundHistory}
                            allowDelete={true}
                            sortOrder={{
                                'oid': 'desc'
                            }} >
                        </JSONTableEditor>
                        <h2>New Ticket
                        </h2>
                        <DynamicForm
                            key={refreshUndergroundKey}
                            procedureDetails={procedureDetailsUndergroundTickets}
                            onSave={handleCompleteUndergroundClose}
                            saveName='Insert'
                            crudType='insert' ></DynamicForm>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUndergroundDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ActivePhase;
