import zhCN from 'date-fns/esm/locale/zh-CN/index';
import moment from 'moment-timezone';
class CentralCalls {
  static formatDateToEastern(utcDate) {
    return moment(utcDate).tz('America/New_York').format('MM/DD/YYYY h:mm A');
  }
  static async upsertData(jsonObj, tableName, upsertType) {
    try {
      const response = await fetch('/api/upsert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tableName, jsonObj, changeType: upsertType }),
      });
      const data = await response.json();
      if (data.error) {
        alert('Error updating data.Check duplicate Lot Number OR reach out for assistance')
      }
      return data.result; // Return the response data if needed
    } catch (err) {
      alert('Error updating data.Check duplicate Lot Number OR reach out for assistance')
      console.error(err);
      // Handle error
    }
  }
  static async fetchTableData(tableName, whereClause) {
    //whereClause = JSON.stringify(whereClause)

    try {
      const response = await fetch('/api/table_select', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tableName, whereClause }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch table data.');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw new Error('An error occurred while fetching table data.');
    }
  }
  static async fetchTableDataNoWhere(tableName) {

    try {
      const response = await fetch('/api/table_select', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tableName }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch table data.');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw new Error('An error occurred while fetching table data.');
    }
  }
  static async callStoredProcedureWithParams(procedureName, param) {
    const requestData = {
      procedureName,
      param
    };
    try {
      const response = await fetch('/api/call-stored-procedureWithParam3', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ procedureName, param: JSON.stringify(param) }),
      });

      if (!response.ok) {
        throw new Error('Failed to query the database');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // Inside the CentralCalls class
  static async exportDataToExcel(startDate, endDate) {
    try {
      const response = await fetch('/api/export', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate: startDate, endDate: endDate }),
      });
      if (!response.ok) {
        throw new Error('Failed to export data.');
      }
      const blob = await response.blob();
      // Create a link to trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();

      return { success: true };
    } catch (error) {
      console.error(error);
      throw new Error('An error occurred while exporting data.');
    }
  }

  static async callStoredProcedureNoParams(procedureName) {
    const requestData = {
      procedureName
    };

    try {
      const response = await fetch('/api/call-stored-procedure', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ procedureName }),
      });

      if (!response.ok) {
        throw new Error('Failed to query the database');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  static formatDate(dateString, format) {
    
    if (format && dateString && dateString !== '' && format === 'noyear') {
      try {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}`;
      } catch {
        console.log('ERROR! Parsing date');
        return dateString;
      }
    } else if (format && dateString && dateString !== '' && format === 'short') {
      try {
        const [year, month, day] = dateString.split('-');
        const lastTwoDigitsOfYear = year.slice(-2);
        return `${month}/${day}/${lastTwoDigitsOfYear}`;
      } catch {
        console.log('ERROR! Parsing date');
        console.log(dateString);
        console.log(format);
        return dateString;
      }
    } else {
      return dateString;
    }
  };
  static formatTotal(total) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);
  }
  
  static sqlFormatdate(dateString) {
    const date = new Date(dateString);

    // Use toISOString() to get an ISO string
    const isoDateString = date.toISOString();

    // Extract the "YYYY-MM-DD" part from the ISO string
    const mysqlDateFormat = isoDateString.split('T')[0];

    return mysqlDateFormat;
  }
}

export default CentralCalls;
