import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CentralCalls from '../../centralCalls';

const ActivePhaseButtonGroup = ({ onFilterSelect }) => {
  const [activeFilter, setActiveFilter] = useState({name_id : 'open', name : 'Open'});
  const phaseTypes = [{name_id : 'open', name : 'Open'}, {name_id : 'scheduled', name : 'Scheduled'}, {name_id : 'upcoming', name : 'Upcoming'}, {name_id : 'complete', name : 'Complete'}, {name_id : 'hold', name : 'On Hold'}, {name_id : 'deleted', name : 'Deleted'}];
  //const inspection = { 'phase_order': 12, 'is_inspection' : 0, name_id : 'slab_inspection', name :'Inspections', header_back_color : '#6A5ACD', main_back_color : '#F0F0FF', header_text_color : 'black'}
  const handleButtonClick = (filter) => {
    setActiveFilter(filter);
    onFilterSelect(filter);
  };
//   useEffect(() => {
//     CentralCalls.fetchTableData('phase_type', {'is_inspection': 0}).then(response => {
//       // Check if the inspection object is already in the array
//       const inspectionExists = response.some(phase => phase.name_id === inspection.name_id);
//       // If inspection is not in the array, add it
//       if (!inspectionExists) {
//         setPhaseTypes([...response, inspection]);
//       } else {
//         setPhaseTypes(response);
//       }
//     });
//   }, []);
  const isActive = (filter) => filter.name_id === activeFilter.name_id;
  
  return (
    <ButtonGroup variant="contained" sx={{ boxShadow: 'none', border: 'none',}}>
      {phaseTypes.sort((a, b) => a.phase_order - b.phase_order).map((phase) => (
        <Button 
          key={phase.name_id}
          onClick={() => handleButtonClick(phase)}
          sx={{
            borderRadius: '15px',
            fontSize: '10.5px',
            fontWeight: isActive(phase) ? 'bold' : 'normal', // Bold for active button
            backgroundColor: isActive(phase) ? 'pink' : 'purple',
            color: isActive(phase) ? 'black' : 'whitesmoke',
            '&:hover': {
              backgroundColor: isActive(phase) ? 'brown' : 'green',
              color: isActive(phase) ? 'black' : 'whitesmoke',
            }, }}   >
          {phase.name}
        </Button>
      ))}
             
    </ButtonGroup>
  );
};

export default ActivePhaseButtonGroup;
