import React, { useState, useEffect } from 'react';
import CentralCalls from '../centralCalls';
import { Typography, makeStyles, TextField, Button, Select, MenuItem, Grid, FormControl, InputLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faSave, faExternalLinkAlt, faMinus } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Arial, sans-serif',
    margin: theme.spacing(2),
  },
  customDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#e0e0e0',  // Adjust the color as needed
    margin: theme.spacing(2, 0),  // Adjust for desired spacing above and below
  },
  customOption: {
    fontSize: '12px',/* Adjust font size as needed */
    fontWeight: 'bold'
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  taskGroup: {
    marginBottom: theme.spacing(2),
  },
  task: {
    marginLeft: theme.spacing(2), // Add indentation
  },
  taskName: {
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  taskDescription: {
    fontSize: '.9rem'
  },
  checkLabel: {
    fontWeight: 'bold',
    fontSize: '0.7em',
  },
  complete: {
    color: 'green',
  },
  completeTested: {
    textDecoration: 'line-through',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  commentList: {
    margin: 0,
    marginLeft: '35px',
    paddingLeft: '1em', // Add left padding for the bullet points
  },
  checkbox: {
    marginRight: theme.spacing(1),
  },
  commentInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  commentInput: {
    flex: 1, // Use flex to grow and take available space
    maxWidth: '200px'
  },
  addCommentButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}));

const TaskList = () => {
  const classes = useStyles();
  const categoryLinks = [
    { category: 'All Phases', relativeLink: '/phase/slab' },
    { category: 'Slab', relativeLink: '/phase/slab' },
    { category: 'Rough', relativeLink: '/phase/rough' },
    { category: 'Underground', relativeLink: '/phase/underground' },
    { category: 'Draintubs', relativeLink: '/phase/draintubs' },
    { category: 'Setout', relativeLink: '/phase/setout' },
    { category: 'Warranty', relativeLink: '/phase/warranty' },
    { category: 'Quality Check', relativeLink: '/phase/slab_qc' },
    { category: 'WIP', relativeLink: '/wip' },
    { category: 'PO', relativeLink: '/phase/po' },
    { category: 'Permit', relativeLink: '/phase/permit' },
    { category: 'Lot', relativeLink: '/phase/lot' },
    { category: 'Menu', relativeLink: '/menu' },
    { category: 'Style/Color', relativeLink: '/style-color' },
    { category: 'Schedule', relativeLink: '/dailySchedule' },
    { category: 'Daily Schedule', relativeLink: '/dailySchedule' },
    { category: 'Employee Schedule', relativeLink: '/employeeSchedule' },
    { category: 'Reports', relativeLink: '/reports' },
    { category: 'Piece Work', relativeLink: '/piecework' },
    { category: 'Materials', relativeLink: '/wip' },
    { category: 'Excel Import/Export', relativeLink: '/export' },
    { category: 'Other' },
  ];
  const baseUrl = window.location.href.split('/')[0];
  const [tasks, setTasks] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const [groupedTasks, setGroupedTasks] = useState({});
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [newTask, setNewTask] = useState({
    category: 'All Phases',
    description: '',
    name: '',
  });
  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    groupTasksByCategory();
  }, [tasks]);

  const fetchTasks = async () => {
    try {
      const fetchedTasks = await CentralCalls.callStoredProcedureWithParams("ui_sp_getTaskData", {});
      // Initialize editedName and editedDescription for each task
      const initializedTasks = fetchedTasks?.map(task => ({
        ...task,
        editedName: task.name,
        editedDescription: task.description,
      }));

      const customSort = (a, b) => {
        const aIsComplete = a.status === 'complete';
        const aIsTested = a.tested === 1;
        const bIsComplete = b.status === 'complete';
        const bIsTested = b.tested === 1;

        // Both a and b have status 'complete' and tested equal to 1
        if (aIsComplete && aIsTested && bIsComplete && bIsTested) {
          return new Date(b.date) - new Date(a.date);
        }

        // Only a has status 'complete' and tested equal to 1
        if (aIsComplete && aIsTested) {
          return 1;
        }

        // Only b has status 'complete' and tested equal to 1
        if (bIsComplete && bIsTested) {
          return -1;
        }

        // Both a and b are either complete or tested (but not both)
        if ((aIsComplete || aIsTested) && (bIsComplete || bIsTested)) {
          return new Date(b.date) - new Date(a.date);
        }

        // Only a is either complete or tested (but not both)
        if (aIsComplete || aIsTested) {
          return 1;
        }

        // Only b is either complete or tested (but not both)
        if (bIsComplete || bIsTested) {
          return -1;
        }

        // Neither a nor b is complete or tested
        return new Date(b.date) - new Date(a.date);
      };

      // Sort tasks by category name and then apply custom sorting within each category
      const sortedTasks = initializedTasks.sort((a, b) => {
        if (a.category === b.category) {
          return customSort(a, b); // Apply custom sorting within the same category
        }
        return a.category.localeCompare(b.category); // Sort by category name
      });

      setTasks(sortedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };
  const toggleCategoryCollapse = (category) => {
    setCollapsedCategories((prevCollapsedCategories) => ({
      ...prevCollapsedCategories,
      [category]: !prevCollapsedCategories[category],
    }));
  };
  const groupTasksByCategory = () => {
    const grouped = {};
    tasks.forEach((task) => {
      if (!grouped[task.category]) {
        grouped[task.category] = [];
      }
      grouped[task.category].push(task);
    });
    setGroupedTasks(grouped);
  };
  const handleEditTask = (taskId) => {
    if (taskId !== editingTaskId) {
      setEditingTaskId(taskId);
    } else {
      setEditingTaskId(null); // Toggle edit mode off for the same task
    }
  };
  const handleSaveEditedTask = async (taskId) => {
    const updatedTask = tasks.find((task) => task.oid === taskId);

    if (updatedTask.editedDescription.trim() !== '') {
      const newValues = {
        name: updatedTask.editedName,
        description: updatedTask.editedDescription,
        oid: taskId
      };

      try {
        await CentralCalls.upsertData(newValues, 'task', 'update');
        fetchTasks();

      } catch (error) {
        console.error('Error updating task:', error);
      }
      setEditingTaskId(null); // Exit edit mode after saving
    }
  };

  const isTaskEditing = (taskId) => editingTaskId === taskId;
  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };
  const handleStatusChange = async (event, task_oid) => {
    const selectedValue = event.target.value;
  
    // Update the status of the task with the matching task_oid
    const updatedTasks = tasks.map(task =>
      task.oid === task_oid ? { ...task, status: selectedValue } : task
    );
  
    try {
      await CentralCalls.upsertData({ status: selectedValue, oid: task_oid }, 'task', 'update');
      setTasks(updatedTasks); // Update the tasks state with the new array
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };
  
  const setTaskComment = (taskId, comment) => {
    const updatedTasks = tasks.map(task =>
      task.oid === taskId ? { ...task, newComment: comment } : task
    );
    setTasks(updatedTasks);
  };
  const handleAddTask = async () => {
    if (newTask.description.trim() !== '') {
      try {
        await CentralCalls.upsertData(newTask, 'task', 'insert');
        setNewTask({ ...newTask, description: '', name: '' });
        fetchTasks();
      } catch (error) {
        console.error('Error adding task:', error);
      }
    }
  };
  const handleAddComment = async (task, comment) => {
    if (comment.trim() !== '') {
      const newComment = {
        task_oid: task.oid,
        comment: comment,
      };

      try {
        await CentralCalls.upsertData(newComment, 'task_comment', 'insert');
        setEditingTaskId(null);
        fetchTasks(); // Refresh the tasks to get the updated comments
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const handleCheckboxChange = async (task) => {
    const updatedTask = { 'oid': task.oid, status: task.status === 'complete' ? 'open' : 'complete' };
    try {
      await CentralCalls.upsertData(updatedTask, 'task', 'update');
      fetchTasks();
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };
  const handleTestedChange = async (task) => {
    const updatedTask = { 'oid': task.oid, tested: task.tested === 1 ? 0 : 1 };
    try {
      await CentralCalls.upsertData(updatedTask, 'task', 'update');
      fetchTasks();
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };
  const filteredTasks = filterStatus === '' ? tasks : tasks.filter(task => task.status === filterStatus);

  return (
    <div className={classes.root}>

      <h1>New Task</h1>
      <div className={classes.task}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={newTask.category}
                onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
                label="Category"   >
                <MenuItem value="All Phases">All Phases</MenuItem>
                <MenuItem value="Slab">Slab</MenuItem>
                <MenuItem value="Rough">Rough</MenuItem>
                <MenuItem value="Underground">Underground</MenuItem>
                <MenuItem value="Draintubs">Drain Tubs</MenuItem>
                <MenuItem value="Setout">Set Out</MenuItem>
                <MenuItem value="Warranty">Warranty</MenuItem>
                <MenuItem value="Quality Check">Quality Check</MenuItem>
                <MenuItem value="WIP">WIP</MenuItem>
                <MenuItem value="PO">PO</MenuItem>
                <MenuItem value="Permit">Permit</MenuItem>
                <MenuItem value="Lot">Lot</MenuItem>
                <MenuItem value="Menu">Menu</MenuItem>
                <MenuItem value="Style/Color">Style/Color</MenuItem>
                <MenuItem value="Schedule">Schedule</MenuItem>
                <MenuItem value="Daily Schedule">Daily Schedule</MenuItem>
                <MenuItem value="Materials">Materials</MenuItem>
                <MenuItem value="Employee Schedule">Employee Schedule</MenuItem>
                <MenuItem value="Admin Tables">Admin Tables</MenuItem>
                <MenuItem value="Reports">Reports</MenuItem>
                <MenuItem value="Excel Import/Export">Excel Import/Export</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Description"
              variant="outlined"
              size="small"
              fullWidth
              value={newTask.description}
              onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
            />
          </Grid>

          {/* <Grid item xs={12} sm={3}>
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              fullWidth
              value={newTask.name}
              onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            />
          </Grid> */}

          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleAddTask}
            >
              Add Task
            </Button>
          </Grid>
        </Grid>
      </div>

      <h1>Tasks</h1>
      <div className={classes.filterContainer}>
        <span>Filter by Status:</span>
        <div>
          <label>
            <input
              type="checkbox"
              value=""
              checked={filterStatus === ''}
              onChange={handleFilterChange}
            />
            All
          </label>
          <label>
            <input
              type="checkbox"
              value="open"
              checked={filterStatus === 'open'}
              onChange={handleFilterChange}
            />
            Open
          </label>
          <label>
            <input
              type="checkbox"
              value="rework"
              checked={filterStatus === 'rework'}
              onChange={handleFilterChange}
            />
            Rework
          </label>
          <label>
            <input
              type="checkbox"
              value="testing"
              checked={filterStatus === 'testing'}
              onChange={handleFilterChange}
            />
            Ready to Test
          </label>
          <label>
            <input
              type="checkbox"
              value="complete"
              checked={filterStatus === 'complete'}
              onChange={handleFilterChange}
            />
            Complete
          </label>
        </div>

      </div>
      <div>
        {Object.keys(groupedTasks).map((category) => (
          <div key={category} className={classes.taskGroup}>
            <Typography variant="h5">
              <button className={classes.addCommentButton} onClick={() => toggleCategoryCollapse(category)} >
                {collapsedCategories[category] ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
              </button>
              {category}
              {categoryLinks.find(item => item.category === category) && (
                <button
                  className={classes.addCommentButton}
                  onClick={() => {
                    const linkObj = categoryLinks.find(item => item.category === category);
                    if (linkObj) {
                      const relativePath = linkObj.relativeLink;
                      const fullLink = baseUrl + relativePath;
                      window.open(fullLink, '_blank');
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}

                  />
                </button>
              )}
            </Typography>
            <div className={classes.customDivider}></div>
            {!collapsedCategories[category] && (
              <ul className={classes.ul}>
                {filteredTasks
                  .filter(task => groupedTasks[category].find(groupedTask => groupedTask.oid === task.oid))
                  .map((task) => (
                    <li key={task.oid}>
                      <div className={classes.task}>
                        <button
                          className={classes.addCommentButton}
                          onClick={() => (isTaskEditing(task.oid) ? handleSaveEditedTask(task.oid) : handleEditTask(task.oid))}
                        >
                          <FontAwesomeIcon icon={isTaskEditing(task.oid) ? faSave : faEdit} />
                        </button>
                        {!isTaskEditing(task.oid) && (
                          <>
                            <select className={classes.customOption} value={task.status} onChange={(event) => handleStatusChange(event, task.oid)}>
                              <option value="open">Open</option>
                              <option value="testing">Ready to Test</option>
                              <option value="rework">Rework</option>
                              <option value="complete">Complete</option>
                            </select>
                            {/* <label className={classes.checkLabel}>
                              <input
                                type="checkbox"
                                className={classes.checkbox}
                                checked={task.status === 'complete'}
                                disabled={task.tested !== 0}
                                onChange={() => handleCheckboxChange(task)}
                              />
                              Complete
                            </label>
                            <label className={classes.checkLabel}>
                              <input
                                type="checkbox"
                                className={classes.checkbox}
                                checked={task.tested === 1}
                                disabled={task.status !== 'complete'} // Disable based on the task status
                                onChange={() => handleTestedChange(task)}
                              />
                              Tested
                            </label> */}
                            <Typography
                              component="span"
                              className={`
                                ${classes.taskDescription} 
                                ${task.status === 'complete' ? classes.complete : ''}
                              `} >
                              ( {new Date(task.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}) - {task.description}
                            </Typography>
                          </>
                        )}
                        {isTaskEditing(task.oid) && (
                          <>
                            {/* <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            value={task.editedName || task.name}
                            onChange={(e) =>
                              setTasks(tasks.map(t => t.oid === task.oid ?
                                { ...t, editedName: e.target.value } :
                                t))
                            }
                          /> */}
                            <TextField
                              label="Description"
                              style={{ width: '750px' }}
                              variant="outlined"
                              size="small"
                              value={task.editedDescription || task.description}
                              onChange={(e) =>
                                setTasks(tasks.map(t => t.oid === task.oid ?
                                  { ...t, editedDescription: e.target.value } :
                                  t))
                              }
                            />
                          </>
                        )}
                        <ul className={classes.commentList}>
                          {task.comments?.map((comment) => (
                            <li
                              key={comment.comment_oid}
                              className={comment.status === 'complete' ? classes.complete : ''}
                            >
                              {comment.comment}
                            </li>
                          ))}
                          {isTaskEditing(task.oid) && (
                            <li>
                              <div className={classes.commentInputContainer}>
                                <input
                                  className={classes.commentInput}
                                  type="text"
                                  placeholder="Add Comment"
                                  value={task.newComment || ''}
                                  onChange={(e) => setTaskComment(task.oid, e.target.value)} />
                                <button className={classes.addCommentButton} onClick={() => handleAddComment(task, task.newComment)} >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskList;
