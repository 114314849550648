import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions, makeStyles, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@material-ui/core/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
    customHeader: {
        borderRadius: '10px',
        fontSize: 'large',
        padding: '0px',
        margin: '0px',
        border: 'black solid'
    }
}))
const OnHoldPhase= ({ phaseType, handleWipLinkClick }) => {

    const handleRemoveOnHoldClose = async (phaseRow) => {  }
    const handleRemoveOnHold = async (phaseRow) => {
        await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open' }, 'phase_status', 'update');
        //setRefresh(new Date().getTime());
      }
    const customFunctionsOnHold = [
        {
          display_name: 'Hold',
          type: 'hold', // type must correspond to a FontAwesome icon name
          func: handleRemoveOnHold
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
      ]
    const procedureDetailsOnHold = {
        procedureName: 'ui_sp_getPhasePageData',
        parameters: { phase_type: phaseType, page_type: 'hold' },  // If there are any parameters, define them here as an object
      };

return (
    <>
    <JSONTableEditor
        key={'hold'}
        procedureDetails={procedureDetailsOnHold}
        onSave={handleRemoveOnHoldClose}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsOnHold} />
    </>
);
}
export default OnHoldPhase;
