class GridCalculator {
    static createLayout(gridArray) {
      let final = ``;
      gridArray.forEach((arr) => {
        final += "'"
        arr.forEach((colNum) => {
          if(colNum !== "."){
            final += "col" + (colNum+1) + ' '
          }
          else{
            final += "." + ' '
          }
        })
        final += "'"
      })
      return final;
    }
    static FilteredHoverOption(grid, startId, endId) {
      const startRow = grid.filter(x => x.id == startId)[0];
      const endRow = grid.filter(x => x.id == endId)[0];
      grid.filter(x => x.status != 'active' && x.set == false && x.row >= startRow.row && x.row <= endRow.row && x.col >= startRow.col && x.col <= endRow.col && x.status != 'closed').forEach(x => {
        x.status = 'openBlock';
      });
      //grid.filter(x => x.row < startRow.row || x.row > endRow.row || x.col < startRow.col || x.col > endRow.col && x.set == false).forEach(x => {
      grid.filter(x => x.status != 'active' && x.set == false && (x.row > endRow.row || x.col > endRow.col) && x.status != 'closed').forEach(x => {
        x.status = 'empty'
      });
      return grid;
    }
    //*Submit
    static ClearFilteredGrid(grid, startId, endId) {
      const startRow = grid.filter(x => x.id == startId)[0];
      const endRow = grid.filter(x => x.id == endId)[0];
      const blockHeight = (endRow.row - startRow.row) + 1
      const blockWidth = (endRow.col - startRow.col) + 1
      grid = grid.filter(x => !(x.row >= startRow.row && x.row <= endRow.row && x.col >= startRow.col && x.col <= endRow.col));
      grid.push({id: startRow.id, height: blockHeight, width: blockWidth, row: startRow.row, col:startRow.col, set: true, status: 'used', backColor : startRow.backColor, spName : startRow.spName})
      grid.filter(x => x.set === false).forEach(x => {
        x.status = 'empty';
      });
      grid.filter(x => x.set === true).forEach(x => {
        x.status = 'used';
      });
      this.SortGrid(grid);
      //grid.sort((a, b)=> a.col < b.col ? -1 : 1).sort((a, b)=> a.row < b.row ? -1 : 1)
      return grid;
    }
    static DeleteChart(grid, id) {
      //const grid = gridShell.slice();
      let maxid = this.MaxId(grid);
      const startRow = grid.filter(x => x.id == id)[0];
      let currRow = startRow.row;
      let currCol = startRow.col; 
      maxid += 1;
      for(let i =0; i < startRow.height; i++){
        let rowNum = (currRow + i);
        for(let c = 0; c < startRow.width; c++){
          let colNum = (currCol + c);
          if(!(rowNum == startRow.row && colNum == startRow.col)){
            grid.push({ id: maxid, height: 1, width: 1, row: rowNum, col: colNum, set: false, status: 'empty' });
            maxid += 1;
          }
          else{
            console.log("skipped" + rowNum + "," + colNum)
          }
          
        }      
      }
      // for(let i =1; i < startRow.width; i++){
      //   grid.push({ id: maxid, height: 1, width: 1, row: currRow, col: (currCol + i), set: false, status: 'empty' });
      //   maxid += 1;
      // }
      startRow.height = 1;
      startRow.width = 1;
      startRow.set = false;
      startRow.status = 'empty';
      startRow.backColor = null;
      grid.sort((a, b)=> a.col < b.col ? -1 : 1).sort((a, b)=> a.row < b.row ? -1 : 1)
      return grid;
    }
    static CanDeleteRow(grid) {
      return true;
      let results = grid.filter(x => x.row == 1);
      if(results.length > 0){
        return false;
      }
      else {
        return true;
      }
    }
    static CanDeleteCol(grid) {
      return true;
      let results = grid.filter(x => x.col == 1);
      if(results.length > 0){
        return false;
      }
      else {
        return true;
      }
    }
    //*Get grid after drop to determine all options for size
    static GetOptionsGrid(grid, selectedPoint) {
      //grid.filter(activeSquare => activeSquare.set == true && activeSquare.row >= selectedPoint.row && activeSquare.col >= selectedPoint.col).forEach(activeSquare =>
      //grid.filter(activeSquare => activeSquare.set == true).forEach(activeSquare =>
      grid.filter(activeSquare => activeSquare.set == true && (activeSquare.row + activeSquare.height - 1) >= selectedPoint.row && (activeSquare.col + activeSquare.width - 1) >= selectedPoint.col).forEach(activeSquare =>
        {
          grid.filter(x => x.row >= activeSquare.row && x.col >= activeSquare.col).forEach(x => {
            x.status = 'closed';
          })
      })
      //* Close any boxes behind or before selected
      grid.filter(x => x.row < selectedPoint.row || x.col < selectedPoint.col).forEach(x => {
        x.status = 'closed';
      })
      grid.filter(x => x.status != 'closed' && x.status != 'active').forEach(x => {
        x.status = 'openBlock';
      })
      grid.filter(x => x.id == selectedPoint.id)[0].status = 'active';
      return grid;
    }
    static SortGrid(grid) {
      return grid.sort((a, b)=> a.col < b.col ? -1 : 1).sort((a, b)=> a.row < b.row ? -1 : 1)
    }
    static RowCount(charts) {
      //***HARD CODEDDDD!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
      //return 1;
      // var b = charts.filter(function(i){
      //   if (i > a) {
      //     a = i;
      //   }
      //   return i == a;
      // });
      //return 4;
      return charts.reduce((p, c) => p.row  > c.row  ? p : c).row ;
      // var p  = charts.reduce((prev, current) => (prev.row > current.row && prev.height > current.height) ? prev : current);
      // console.log(p);
      // console.log('results above')
      // return (p.row + p.height) - 1;
      // console.log(charts.reduce((prev, current) => (prev.y > current.y) ? prev : current));
      // return charts.reduce((prev, current) => (prev.y > current.y) ? prev : current);
      // console.log('row count:' + charts.reduce((accumulator, object) => {
       //return accumulator + object.height;
      // }, 0));
      // return charts.reduce((accumulator, object) => {
      //   return accumulator + object.height;
      // }, 0);
      return charts.reduce((p, c) => p.row  > c.row  ? p : c).row ;
    }
    static ColumnCount(charts) {
      //return 3;
      return 3;
      var c = charts.reduce((prev, current) => (prev.col > current.col) ? prev : current);
      var colCount = charts.reduce((prev, current) => (prev.col == c.col && prev.width > current.width) ? prev : current).col + (charts.reduce((prev, current) => (prev.col = c.col && prev.width > current.width) ? prev : current).width - 1);
      //console.log('maxCol:' + colCount)
      return 3;
      // charts.filter((value, index, self) => {
      //   return self.findIndex((v) => v.date === value.date) === index;
      // });
      // var inputs = media.reduce((passedArray, row) => {
      //   passedArray.push(row);
      //   return passedArray;
    //}, []);
      return (c.width + c.col) -1;
      //return charts.reduce((p, c) => p.col > c.col ? p : c).col;
    }
    static MaxId(charts) {
      return charts.reduce((p, c) => p.id > c.id ? p : c).id;
    }
    static CreateGrid(charts, maxRow, maxCol) {
      let chartId = 0;
      if(!maxRow){
        maxRow = 5;
      }
      if(!maxCol){
        maxCol = 3;
      }
      // let maxRow = RowCount(charts);
      // let maxCol = ColumnCount(charts);
      // var c = charts.reduce((prev, current) => (prev.col > current.col) ? prev : current);
      // var colCount = charts.reduce((prev, current) => (prev.col == c.col && prev.width > current.width) ? prev : current).col + (charts.reduce((prev, current) => (prev.col = c.col && prev.width > current.width) ? prev : current).width - 1);
     // console.log('from main' +maxCol);
      //let maxCol = 3;
      //console.log('from main' +maxCol);
      const gridMap = Array.from({ length: maxRow }, () => Array.from({ length: maxCol }, () => '.'));
      charts.forEach((cht) => {
        let r = 0;        
        while(r < cht.height) {
          let c = 0;
          while(c < cht.width){          
            try {
              gridMap[cht.row-1 + r][cht.col-1 + c] = chartId;
            }
            catch{
              console.log('porlblem2!!')
            }          
            c++;
          }
          r++;
        }        
        chartId++;
      })
      return this.createLayout(gridMap);
    }
}

  export default GridCalculator;