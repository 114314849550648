
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
// Define the component and destructure the prop from the component's props
const WipPermitSection = ({ selectedLotOid }) => {
    const [PermitStatus, setPermitStatus] = useState(null);
    useEffect(() => {
          if (selectedLotOid) {
            runPermitStatus();
          }
      }, [selectedLotOid]);
    const onPermitUpdate = (obj) => { }
    const runPermitStatus = async () => {
        const lotIdToSend = selectedLotOid && !isNaN(selectedLotOid) ? selectedLotOid : 0;
        var stat = await CentralCalls.callStoredProcedureWithParams('check_permitRequired', { 'lot_oid': lotIdToSend });
        setPermitStatus(stat.status);
      }
    const PermitProcedureDetails = useMemo(() => ({
        procedureName: ' ui_sp_wipPermitData',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
      }), [selectedLotOid]);
    return (
        <div>
            <h2>Permit ({PermitStatus})</h2>
            <JSONTableEditor
                procedureDetails={PermitProcedureDetails}
                onsave={onPermitUpdate}
                sortOrder={{ 'date_applied': 'asc' }} />
        </div>
    );
}

export default WipPermitSection;