
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton,MenuItem, Select, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
import MaterialSelector from './MaterialSelector';

const WipMaterialSection = ({ selectedLotOid }) => {
    const [materialTotal, setMaterialTotal] = useState(null);
    const [refreshMaterialSelectKey, setRefreshMaterialSelectKey] = useState(5000);
    const [newMaterialModelOid, setNewMaterialModelOid] = useState(null);
    const [addMaterialRoomDialogOpen, setAddMaterialRoomDialogOpen] = useState(false);
    const [selectedMaterialAddRoom, setSelectedMaterialAddRoom] = useState({ oid: 0, name: 'Select' });
    const [triggerMaterialTotalRefresh, setTriggerMaterialTotalRefresh] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [roomsPerLot, setRoomsPerLot] = useState([]);
    const [addRoomToLotOid, setAddRoomToLotOid] = useState(0);
    const customFunctionsEditMaterials = [ ]
    const addNewMaterialModel = async () => {
        await CentralCalls.upsertData({ 'lot_room_oid': selectedMaterialAddRoom, 'material_model_oid': newMaterialModelOid }, 'room_material', 'insert');
        setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
      }
    
    const MaterialTableProcedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_materialsByLotPage',
        parameters: { 'lot_oid': selectedLotOid },
      }), [selectedLotOid])
    useEffect(() => {
        const getMaterialTotal = async () => {
          if (selectedLotOid) {
            var tot = await CentralCalls.callStoredProcedureWithParams('sp_getMaterialCostTotal', { 'lot_oid': selectedLotOid });
            setMaterialTotal(tot[0].total);
          }
        }
        getMaterialTotal();
      }, [triggerMaterialTotalRefresh, newMaterialModelOid, selectedLotOid]);
    const sendToPrint = async () => {
        //const urlParams = 'qry=' + qry + '&employee_oids=' + selectedPlumbers.map(item => item.oid) + '&start_date=' + CentralCalls.sqlFormatdate(startDate) + '&end_date=' + CentralCalls.sqlFormatdate(endDate);
        const path = '/printMaterials?lot_oid=' + selectedLotOid;
        window.open(path, "_blank");
    }
    const handleMaterialModelSelected = async (modelRow) => {
        await setSelectedMaterialAddRoom(modelRow.room_oid);
        setNewMaterialModelOid(modelRow.model_oid);
      }
      const addNewRoomToLot = async () => {
        if (addRoomToLotOid && addRoomToLotOid > 0) {
          await CentralCalls.upsertData({ 'lot_oid': selectedLotOid, 'material_room_oid': addRoomToLotOid }, 'lot_room', 'insert');
          setAddRoomToLotOid(0);
          setAddMaterialRoomDialogOpen(false);
          setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
        }
      }
    const getRoomList = async () => {
        var rooms = await CentralCalls.callStoredProcedureWithParams('sp_getAvailableRoomsPerLot', { 'lot_oid': selectedLotOid })
        setRoomList(rooms);
      }
    const handleSaveMaterialEditPostRun = async () => {
        setTriggerMaterialTotalRefresh(prev => !prev);
        getRoomList();
      }
    return (
        <>
            <h2>
                <IconButton
                    color="primary"
                    aria-label="save"
                    onClick={() => { sendToPrint() }}
                    size="medium">
                    <FontAwesomeIcon icon={faPrint} />
                </IconButton>
                Materials
                ({CentralCalls.formatTotal(materialTotal)})
                <Tooltip title="Add Room">
                    <IconButton color="primary" aria-label="save" onClick={() => setAddMaterialRoomDialogOpen(true)} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h2>
            <div style={{ display: 'flex' }}>

            </div>

            <JSONTableEditor
                procedureDetails={MaterialTableProcedureDetails}
                onSave={handleSaveMaterialEditPostRun}
                key={selectedMaterialAddRoom}
                customFunctions={customFunctionsEditMaterials}
                sortOrder={{
                    'material_type': 'asc',
                    'material_desc': 'asc',
                    'material_model_number': 'asc'
                }}   >
            </JSONTableEditor>
            <h4>Add Material </h4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: '1' }}>
                    <MaterialSelector key={refreshMaterialSelectKey} onMaterialModelChange={handleMaterialModelSelected} lot_oid={selectedLotOid} />
                </div>
            </div>
            <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={newMaterialModelOid === null} aria-label="save" onClick={() => addNewMaterialModel()} size="medium">
                Insert
            </Button>

            <Dialog fullWidth={true} maxWidth='sm' open={addMaterialRoomDialogOpen} onClose={() => setAddMaterialRoomDialogOpen(false)}>
        <DialogContent >
          <>
            <h2>Add Room</h2>
            <Select style={{ width: '300px' }}
              value={addRoomToLotOid}
              onChange={(event) => { setAddRoomToLotOid(event.target.value) }} >
              <MenuItem key={0} value={0}>
                Select Room
              </MenuItem>
              {roomList &&
                roomList.slice().sort((a, b) => a.name.localeCompare(b.name))
                  .map((option) => (
                    <MenuItem key={option.oid} value={option.oid}>
                      {option.name}
                    </MenuItem>
                  ))}
            </Select>

            <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={addRoomToLotOid === 0} aria-label="save" onClick={() => addNewRoomToLot()} size="medium">
              Insert
            </Button>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddMaterialRoomDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        </>
    );
}

export default WipMaterialSection;