import React from 'react';
import profilePic from './../ProfilePic1.png';
import profile2 from './../ProfilePic4.png';
import './About.css'; // Import your custom CSS file

const About = () => {
    return (
      <div className="about-container">
        <header className="header">
          <h1>About</h1>
        </header>
        <div className="profile-info">
          
          <div className="profile-details" style={{ width:'100%'}}>
            <h2>Chris Katsaros</h2>
            <h4>Software Architect</h4>
            <p>
            As a self-taught software architect with an entrepreneurial mindset, I excel in environments where I can directly apply my knowledge to real-world challenges. My approach to learning and problem-solving is grounded in hands-on experience and a deep understanding of business operations. This combination enables me to create software solutions that are not only efficient and user-centric but also strategically aligned with business opportunities. Driven by a desire to innovate new solutions with the most modern techniques, I'm passionate about staying at the forefront of the ever-evolving tech landscape.
            </p>
            <h4>Plumbing Operations Application</h4>
            <p className="career-summary">
            This application, designed for a small business owner, fills a niche where existing products, including Service Titan, fell short in supporting his business operations. Drawing on input from the owner of a plumbing business managing hundreds of jobs simultaneously, I developed this tool. It functions as a customized, small-scale ERP system, featuring sequential phases, cost, and employee tracking. This version is an open sandbox variant of the original application, featuring operational data that has been anonymized and processed using AI. 
            Additionally, it's a cloud-based SaaS solution, engineered in a way that allows for a multi-tenant architecture if needed.
            </p>
           
          </div>
          
          <div style={{float:'right'}} className="download-resume">
            <br/>
          <img className="profile-pic" width={480} height={660} src={profilePic} alt="Chris Katsaros" />
          <br/>
        <a href="/Christopher_Katsaros_Resume.pdf" download="Katsaros_Resume.pdf">
          Download My Resume
        </a>
      </div>
         
        </div>
        <div className="details-container">
           Email: chris.katsaros12@gmail.com
        </div>
      </div>
    );
  };
  
  export default About;
  