import { LineChart, Line, CartesianGrid, XAxis, YAxis, BarChart, Bar, ResponsiveContainer } from 'recharts';
import React, { useEffect, useState } from 'react';
import CentralCalls from '../../centralCalls';
import { GridSpinner, PushSpinner, BallSpinner } from "react-spinners-kit";

const BarChartComponent = ({ data, myHeight }) => (
    <ResponsiveContainer width={'100%'}>
        <BarChart height={myHeight} data={data}>
            <Bar type="monotone" dataKey="series1" stroke="#8884d8" />
            <Bar type="monotone" dataKey="series2" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="xAxis" />
            <YAxis />
        </BarChart>
    </ResponsiveContainer>
);
const LineChartComponent = ({ data, myHeight }) => (
    <ResponsiveContainer width={'100%'}>
        <LineChart height={myHeight} data={data}>
            <Line type="monotone" dataKey="series1" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="xAxis" />
            <YAxis />
        </LineChart>
    </ResponsiveContainer>
);
export default function ReChart({ chtData, params, spName, type, myHeight }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const getChartData = async (data) => {
        try {
            setData(data);
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        //if (chtData.data_from_props === true) {
        //if (params[chtData.props_data_name]) {
        //getChartData(params[chtData.props_data_name])
        //} else {
        //getChartData([])
        //}
        //} else {
        CentralCalls.callStoredProcedureWithParams(spName, params).then(response => { setData(response) })
        //   if (chtData.source === 'postgres') {
        //     dbCalls.CallPostGresFunction(getChartData, 'business_opp', spName, params);
        //   } else {
        //     dbCalls.CallProcedureWithParams(getChartData, spName, params);
        //   }
        //}
    }, [params, spName]);
    switch (type) {
        case "line":
            return <LineChartComponent data={data} myHeight={myHeight} />;
        case "bar":
            return <BarChartComponent data={data} myHeight={myHeight} />
        case "data_pairs":
            if (!data || data.length === 0) {
                return <h4>No Rows</h4>
            } else {
                return (
                    <table className={'table striped bordered hover'} > <tbody>
                        {Object.keys(data).map(col => { return (<tr> <th>{col} : </th><td>{data[col]}</td></tr>); })}
                    </tbody></table>
                )
            }
        case "table":
            if (!data) {
                return <h4>No Rows</h4>
            } else {
                // Sort columns by order
                const sortedColumns = data.columns.sort((a, b) => a.order - b.order);
                console.log(sortedColumns);
                return (
                    <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                         {data?.title ? <h4>{data.title}</h4> : null}
                        <table className={'table striped bordered hover'}  >
                            <thead>
                                <tr>
                                    {sortedColumns.map(column => {
                                        return (!column.hasOwnProperty('hide') || column.hide === false) ? <th key={column.name}>{column.name}</th> : null;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex} style={{ color: row.row_text_color ? row.row_text_color : 'black' }}>
                                            {sortedColumns.map(column => {
                                                return (!column.hasOwnProperty('hide') || column.hide === false) ? (
                                                    <td key={column.name + rowIndex}>{row[column.name]}</td>
                                                ) : null;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            }


        default:
            return null;
    }
}
