import React from 'react';
import './Popup.css'; // Import your CSS file for styling
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popup: {
    width: '300px',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px', // Add rounded corners
  },
  content: {
    marginBottom: '16px', // Add some spacing below the text
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end', // Align buttons to the right
    marginTop: '20px',
  },
  button: {
    padding: '8px 16px',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    marginRight: '10px', // Add spacing between buttons
    borderRadius: '4px', // Add rounded corners to buttons
    fontWeight: 'bold', // Make text bold
    transition: 'background-color 0.3s', // Smooth background color transition on hover
  },
  buttonCancel: {
    backgroundColor: '#ccc',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#aaa', // Darken background on hover
    },
  },
  buttonConfirm: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d32f2f', // Darken background on hover
    },
  },
}));

const DeleteConfirmationPopup = ({ displayText, onDelete, onCancel }) => {
  const classes = useStyles();
  return (
    <div className={classes.popup}>
      <p className={classes.content}>{displayText}</p>
      <div className={classes.buttonContainer}>
        <button className={classes.button + ' ' + classes.buttonCancel} onClick={onCancel}>
          Cancel
        </button>
        <button className={classes.button + ' ' + classes.buttonConfirm} onClick={onDelete}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;