import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, DialogActions, makeStyles, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import DynamicForm from '../../DynamicForm';
import JSONTableEditor from '../../JSONTableEditor';
import CentralCalls from '../../../centralCalls';
import { useTheme } from '@material-ui/core/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../../DeleteConfirmationPopup';
import ActivePhase from '../parts/activePhase';
import CompletePhase from '../parts/completedPhase';
import DeletedPhase from '../parts/deletedPhase';
import OnHoldPhase from '../parts/onHoldPhase';
import LotFilter from '../../workInProgress/LotFilter';
import ActivePhaseButtonGroup from '../activePhaseButtonGroup';
const useStyles = makeStyles(theme => ({
  customHeader: {
    borderRadius: '10px',
    fontSize: 'large',
    padding: '0px',
    margin: '0px',
    border: 'black solid'
  }
}))
const BasePhase = ({ phaseType }) => {
  const classes = useStyles();
  const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
  const [UndergroundDialogOpen, setUndergroundDialogOpen] = useState(false);
  const [addQCDialogOpen, setAddQCDialogOpen] = useState(false);
  const [PunchDialogOpen, setPunchDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [LotOid, setLotOid] = useState(null);
  const [filter,setFilter] = useState({name_id : 'open', name : 'Open'});
  //const [phaseName, setPhaseName] = useState('');
//   const [isQc, setIsQc] = useState(0);
  //const [isInspection, setIsInspection] = useState(0);
  const [PhaseOid, setPhaseOid] = useState(null);
  const [lotTitle, setLotTitle] = useState('not title');
  const [punchLotOid, setPunchLotOid] = useState(null);
  const [refreshUndergroundKey, setRefreshUndergroundKey] = useState(0);
  const [AddQCValid, setAddQCValid] = useState(null);
  const [NewQCLotOid, setNewQCLotOid] = useState(0);
  const [selectedPhaseForQC, setSelectedPhaseForQC] = useState(0);
  const [selectedSecondaryPhaseForQC, setSelectedSecondaryPhaseForQC] = useState('slab_inspection');
  const [qcPhaseOptions, setQcPhaseOptions] = useState([]);
  const [secondaryInspectionLotOid,setSecondaryInspectionLotOid] = useState(0);
  const [phaseName, setPhaseName] = useState('');
  const [isQc, setIsQc] = useState(0);
  const [isInspection, setIsInspection] = useState(0);
  const [qcTitle, setQcTitle] = useState('UNSET');
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      const phase_nm = await CentralCalls.fetchTableData('phase_type', { name_id: phaseType });
      setPhaseName(phase_nm[0].name);
      setIsQc(phase_nm[0].is_qc);
      setIsInspection(phase_nm[0].is_inspection);
      if (phase_nm[0].is_inspection === 1) {
        setQcTitle("Add Inspection");
      } else {
        setQcTitle("Add Quality Check");
      }
    };

    fetchData();
  }, [phaseType], [isInspection]);
  useEffect(() => {
    const getLotTitle = async () => {
      const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: LotOid });
      setLotTitle(title.title);
    }
    getLotTitle();
  }, [LotOid]);
useEffect(() => {
    const fetchData = async () => {
        const phase_nm = await CentralCalls.fetchTableData('phase_type', { name_id: phaseType });
        setPhaseName(phase_nm[0].name);
        setIsQc(phase_nm[0].is_qc);
        setIsInspection(phase_nm[0].is_inspection);
        if (phase_nm[0].is_inspection === 1) {
            setQcTitle("Add Inspection");
        } else {
            setQcTitle("Add Quality Check");
        }
    };

    fetchData();
}, [phaseType], [isInspection]);
  const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Confirmation Modal"  >
        <h2>Confirm</h2>
        <p>Are you sure you want to proceed?</p>
        <button onClick={onConfirm}>Yes</button>
        <button onClick={onClose}>No</button>
      </Modal>
    );
  };
//   const handleCompletePhase = async (phaseRow) => {
//     setStatusDialogOpen(true);
//     setLotOid(phaseRow.lot_oid);
//     setPhaseOid(phaseRow.phase_oid);
//   };
  const closePunchDialog = async () => {
    setPunchDialogOpen(false);
    setPunchLotOid(null);
  }
  const handleLotSelected = async (lot_oid) => {
    setPunchLotOid(lot_oid);
  }
//   const checkDisplayComplete = (phaseRow) => {
//     if ((phaseRow.date_scheduled && phaseRow.date_scheduled !== null && phaseRow.employee_oids && phaseRow.employee_oids !== '[]') || (phaseRow.is_inspection && phaseRow.date_scheduled && phaseRow.date_scheduled !== null)) { return true; } else { return false; }
//   }
//   const checkDisplayNonComplete = (phaseRow) => {
//     if (phaseRow.is_single_status === 0 && phaseRow.is_qc !== 1 && phaseRow.is_inspection !== 1) { return true; } else { return false; }
//   }
//   const checkDisplayOnHold = (phaseRow) => {
//     return true;
//     // if (phaseType === 'underground') { return true } else { return false; }
//   }
//   const checkDisplayUndergroundApproval = (phaseRow) => {
//     if (phaseType === 'underground') { return true; } else { return false; }
//   }
   const handleWipLinkClick = async (phaseRow) => {
     const path = '/wip?lot_oid=' + phaseRow.lot_oid;
     navigate(path);
   }
  const handleCompletephaseClose = async (phaseRow) => {
    setStatusDialogOpen(false);
    setPhaseOid(phaseRow.phase_oid);
    if (phaseRow.phase_type === 'slab') {
      const updateObj = {
        oid: LotOid,
        sewer_location: phaseRow.sewer_location,
        water_location: phaseRow.water_location,
      };
      try {
        const response = await fetch('/api/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tableName: 'lot', updateObj: updateObj }),
        });
        const data = await response.json();
      } catch (err) {
        console.error(err);
        // Handle error
      }
    }
    if (phaseRow.finished === 1 && (phaseRow.status === 'fail')) {
      const insertObj = {
        phase_oid: phaseRow.phase_oid,
        comments: phaseRow.comments,
        employee_oids: JSON.stringify(phaseRow.employee_oids)
      };
      await CentralCalls.upsertData(insertObj, 'phase_status', 'insert');
      setRefresh(new Date().getTime());
    }
    if (isQc === 1 || isInspection === 1) {
      CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'qc_status': phaseRow.qc_status }, 'phase', 'update');
    }
    //if (isInspection === 1) {
    //  CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'ins_status': phaseRow.qc_status }, 'phase', 'update');
    //}
    if ((isQc === 1 || isInspection === 1) && phaseRow.qc_status === 'fail') {
      failedQCAutoPunch(phaseRow);
    }
  };
  const handleCompleteUndergroundClose = async (undergroundRow) => {
    setUndergroundDialogOpen(false);
  }
  const handleSavePhaseStatus = async (phaseRow) => {
     setStatusDialogOpen(!StatusDialogOpen);
   }
  const handleSavePhaseSaveEdit = async (phaseRow) => { }
//   const handleNewUndergroundTicket = async () => {
//     await CentralCalls.upsertData({ 'phase_oid': PhaseOid }, 'underground', 'insert');
//     setRefreshUndergroundKey(refreshUndergroundKey + 1);
//   }
//   const handleSave = async (phaseRow) => {
//     //Need to convert on old true/false to tinyInt
//     //const on_hold = phaseRow.on_hold ? 1 : 0;
//     CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'date_scheduled': phaseRow.date_scheduled, 'builder_date': phaseRow.builder_date, 'is_punch': phaseRow.is_punch ? 1 : 0 }, 'phase', 'update');
//   };
//   const handleRevertRecent = async (phaseRow) => {
//     //Called after save of revert

//   }
//   const handleRevertRecentClick = async (phaseRow) => {
//     //called when you click the custom fucntion icon in this case revert
//     await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open', 'finished': 0 }, 'phase_status', 'update');
//     setRefresh(new Date().getTime());
//   }
//   const handleRemoveOnHoldClose = async (phaseRow) => {
//     //called when you click the custom fucntion icon in this case revert
//     //await CentralCalls.upsertData({ 'oid' : phaseRow.oid, 'on_hold' : 0}, 'phase', 'update');
//     //setRefresh(new Date().getTime());
//   }
//   const handleRemoveOnHold = async (phaseRow) => {
//     await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open' }, 'phase_status', 'update');
//     setRefresh(new Date().getTime());
//   }
//   const handlePutOnHold = async (phaseRow) => {
//     await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'hold', 'status_date': new Date() }, 'phase_status', 'update');
//     setRefresh(new Date().getTime());
//   }
//   const handleRemoveDeleted = async (phaseRow) => {
//     await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'open' }, 'phase_status', 'update');
//     setRefresh(new Date().getTime());
//   }
//   const handleRevertNonCompleteRecentClick = async (phaseRow) => {
//     await CentralCalls.callStoredProcedureWithParams('revert_phase_status', { 'phase_oid': phaseRow.phase_oid });
//     //await CentralCalls.upsertData({oid : phaseRow.oid, finished : 0});
//     setRefresh(new Date().getTime());
//     //await CentralCalls.upsertData({ 'oid' : phaseRow.oid, 'complete': 0, 'finished': 0}, 'phase_status', 'update');
//     //await CentralCalls.upsertData({ 'oid' : slabRow.oid, 'complete': 0, 'finished': 0}, 'phase_status', 'update');
//   }
//   const handleDisplayUndergroundApprovalClick = async (phaseRow) => {
//     setRefresh(new Date().getTime());
//     setPhaseOid(phaseRow.phase_oid);
//     setLotOid(phaseRow.lot_oid);
//     setUndergroundDialogOpen(true);
//   }
    const onFilterSelect  = async (filter) => {
        console.log(filter.name_id)
        setFilter(filter);
    }
//   const handleCancelClick = async (phaseRow) => {
//     await CentralCalls.upsertData({ 'oid': phaseRow.oid, 'status': 'cancel', 'status_date': new Date() }, 'phase_status', 'update');
//     setRefresh(new Date().getTime());
//   }
  const addPunch = async () => {
    setPunchDialogOpen(true);
  }
  const OpenQCDialogOpen = async () => {
    setAddQCDialogOpen(true);
    setQcPhaseOptions([]);
    setSelectedPhaseForQC(0);
    setAddQCValid(false);
  }
  const handleAddPunchClick = async () => {
    const newPhase = await CentralCalls.upsertData({ 'lot_oid': punchLotOid, 'phase_type': phaseType, 'is_punch': 1 }, 'phase', 'insert');
    await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
    closePunchDialog();
  }
  const failedQCAutoPunch = async (phaseRow) => {
    const punchPhaseType = phaseType.split('_')[0];
    const newPhase = await CentralCalls.upsertData({ 'lot_oid': LotOid, 'phase_type': punchPhaseType, 'is_punch': 1, 'punch_comments': phaseRow.comments, 'builder_date': phaseRow.date_scheduled }, 'phase', 'insert');
    //if(phaseRow.phase_type === 'slab' || phaseRow.phase_type === 'rough' || phaseRow.phase_type === 'setout')
    //const qcPhase = await CentralCalls.upsertData({ 'lot_oid': LotOid, 'phase_type': punchPhaseType, 'is_punch': 1 }, 'phase', 'insert');
    await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
  }
  const setQCPhaseOptions  = async (selected_lot_oid, secondary_phase_type) => {
    console.log('setting qc phase options...' + selected_lot_oid);
    const qcForPhaseType = phaseType.split('_')[0];
    var this_phase_type = qcForPhaseType;
    console.log('phase type...')
    console.log(qcForPhaseType);
    console.log('isInspection...' + isInspection);
    if (isInspection === 1) {
      console.log(secondary_phase_type.split('_')[0])
      this_phase_type = secondary_phase_type.split('_')[0];
    }
    //console.log({ lot_oid: parseInt(lot), phase_type: this_phase_type, is_inspection : isInspection, inspection_phase_type : selectedSecondaryPhaseForQC })
    const result = await CentralCalls.callStoredProcedureWithParams('sp_checkLotExistingQC', { lot_oid: parseInt(selected_lot_oid), phase_type: this_phase_type, is_inspection: isInspection, inspection_phase_type: selectedSecondaryPhaseForQC });
    if (!result || result.length === 0) {
      setQcPhaseOptions([], selectedSecondaryPhaseForQC);
      //CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
      //setAddQCValid(false);         
    } else {
      setNewQCLotOid(selected_lot_oid);
      //setAddQCValid(true);
      setQcPhaseOptions(result,selectedSecondaryPhaseForQC);
    }
  }
  const confirmLotQCAddOption = async (lot) => {
    setQCPhaseOptions(lot, selectedSecondaryPhaseForQC);
    setSecondaryInspectionLotOid(lot);
  }
  const insertNewQC = async () => {
    var phase_type_now = phaseType;
    if(isInspection){
      phase_type_now = selectedSecondaryPhaseForQC;
    }
    const phaseRow = await CentralCalls.upsertData({ lot_oid: NewQCLotOid, phase_type: phase_type_now, qc_phase_oid: selectedPhaseForQC }, 'phase', 'insert');
    await CentralCalls.upsertData({ 'phase_oid': phaseRow.oid }, 'phase_status', 'insert');
    setAddQCDialogOpen(false);
  }
  const handleSelectedPhaseForQCChange = async (event) => {
    const oid = event.target.value;
    if (oid !== 0) {
      setAddQCValid(true);
    }
    else {

    }
    setSelectedPhaseForQC(oid);
  }
  const handleSelectedSecondaryPhaseForQCChange = async (event) => {
    const phase_type = event.target.value;
    if (isInspection && selectedSecondaryPhaseForQC === 0) {
      setAddQCValid(true);
    }
    await setSelectedSecondaryPhaseForQC(phase_type);
    setQCPhaseOptions(secondaryInspectionLotOid, phase_type);
  }
//   const customFunctions = [
//     {
//       display_name: 'Complete',
//       type: 'check', // type must correspond to a FontAwesome icon name
//       func: handleCompletePhase,
//       displayCheck: checkDisplayComplete
//     },
//     {
//       display_name: 'Revert',
//       type: 'revert', // type must correspond to a FontAwesome icon name
//       func: handleRevertNonCompleteRecentClick,
//       displayCheck: checkDisplayNonComplete
//     },
//     {
//       display_name: 'Underground Ticket',
//       type: 'approval', // type must correspond to a FontAwesome icon name
//       func: handleDisplayUndergroundApprovalClick,
//       displayCheck: checkDisplayUndergroundApproval
//     },
//     {
//       display_name: 'Hold',
//       type: 'pause', // type must correspond to a FontAwesome icon name
//       func: handlePutOnHold,
//       displayCheck: checkDisplayOnHold
//     },
//     {
//       display_name: 'Cancel',
//       type: 'cancel',
//       func: handleCancelClick
//     },
//     {
//       display_name: 'WIP',
//       type: 'overview',
//       func: handleWipLinkClick
//     }
//   ]
//   const customFunctionsRecentComplete = [
//     {
//       display_name: 'Revert',
//       type: 'revert', // type must correspond to a FontAwesome icon name
//       func: handleRevertRecentClick
//     },
//     {
//       display_name: 'WIP',
//       type: 'overview',
//       func: handleWipLinkClick
//     }
//   ]
//   const customFunctionsOnHold = [
//     {
//       display_name: 'Hold',
//       type: 'hold', // type must correspond to a FontAwesome icon name
//       func: handleRemoveOnHold
//     },
//     {
//       display_name: 'WIP',
//       type: 'overview',
//       func: handleWipLinkClick
//     }
//   ]
//   const customFunctionsDeleted = [
//     {
//       display_name: 'Revert',
//       type: 'undo', // type must correspond to a FontAwesome icon name
//       func: handleRemoveDeleted
//     },
//     {
//       display_name: 'WIP',
//       type: 'overview',
//       func: handleWipLinkClick
//     }
//   ]
  const customFunctionsEmpty = [

  ]
//   const procedureDetails = {
//     procedureName: 'ui_sp_getPhasePageData',
//     parameters: { phase_type: phaseType, page_type: 'current' },  // If there are any parameters, define them here as an object
//   };
//   const procedureDetailsOnHold = {
//     procedureName: 'ui_sp_getPhasePageData',
//     parameters: { phase_type: phaseType, page_type: 'hold' },  // If there are any parameters, define them here as an object
//   };
  const procedureDetailsUndergroundHistory = {
    procedureName: 'ui_sp_wipUndergroundTickets',
    parameters: { lot_oid: LotOid },
  }
  const handleSaveUndergroundHistory = async (phaseRow) => { }
//   const procedureDetailsDeleted = {
//     procedureName: 'ui_sp_getPhasePageData',
//     parameters: { phase_type: phaseType, page_type: 'deleted' },  // If there are any parameters, define them here as an object
//   };
  const procedureDetailsCompletion = {
    procedureName: 'ui_sp_getPhaseCompletionPageData',
    parameters: { phase_oid: PhaseOid, lot_oid: LotOid },  // If there are any parameters, define them here as an object
  };
  const procedureDetailsCompletionInsert = {
    procedureName: 'ui_sp_getPhaseCompletionPageDataInsert',
    parameters: { phase_oid: PhaseOid, phase_type: phaseType, lot_oid: LotOid },  // If there are any parameters, define them here as an object
  };
//   const procedureDetailsRecentComplete = {
//     procedureName: 'ui_sp_getPhasePageData',
//     parameters: { phase_type: phaseType, page_type: 'recent' },
//   }
  const procedureDetailsUndergroundTickets = {
    procedureName: 'ui_getUndergroundTickets',
    parameters: { phase_oid: PhaseOid, lot_oid: LotOid }
  }

  return (
    <>
          <h2> {phaseName === 'Slab Inspection' ? 'Inspections' : phaseName}
        {isQc !== 1 && (
          <Tooltip title="Add Punch">
            <IconButton color="primary" aria-label="save" onClick={addPunch} size="medium">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        )}
        {isQc === 1 && (
          <Tooltip title={qcTitle}>
            <IconButton color="primary" aria-label="save" onClick={() => { OpenQCDialogOpen() }} size="medium">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        )}
      </h2>
       
      <ActivePhaseButtonGroup onFilterSelect={onFilterSelect}  ></ActivePhaseButtonGroup>
       <h3>{filter.name}</h3>
      {filter.name_id === 'open' && (
        <ActivePhase phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}

      {filter.name_id === 'hold' && (
        <OnHoldPhase phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}

      {filter.name_id === 'complete' && (
        <CompletePhase phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}

      {filter.name_id === 'deleted' && (
        <DeletedPhase phaseType={phaseType} handleWipLinkClick={handleWipLinkClick} />
      )}
      {/* <JSONTableEditor
        procedureDetails={procedureDetails}
        onSave={handleSave}
        sortOrder={{
          'enabled': 'desc',
          //  'status_date': 'desc',
          'date_scheduled': 'asc',
          'builder_date': 'asc',
          'builder': 'asc',
          'neighborhood': 'asc',
          'lot_num': 'asc',
          'phase_for_inspection': 'asc'
        }}
        customFunctions={customFunctions} /> */}
      {/* <h2>On Hold</h2> */}
      {/* <JSONTableEditor
        key={'hold'}
        procedureDetails={procedureDetailsOnHold}
        onSave={handleRemoveOnHoldClose}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsOnHold} /> */}
     
      {/* <JSONTableEditor
        key={'recent'}
        procedureDetails={procedureDetailsRecentComplete}
        onSave={handleRevertRecent}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsRecentComplete} /> */}
      {/* <h2>Recently Deleted</h2> */}
      {/* <JSONTableEditor
        key={'deleted'}
        procedureDetails={procedureDetailsDeleted}
        onSave={handleRemoveOnHoldClose}
        sortOrder={{
          'date_scheduled': 'desc'
        }}
        hideEdit={true}
        customFunctions={customFunctionsDeleted} /> */}
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>Finish Task</h2>
                        <h4>{lotTitle}</h4>
                        <DynamicForm procedureDetails={procedureDetailsCompletionInsert}
                            onSave={handleCompletephaseClose}
                            saveName='Complete'
                            crudType='update' ></DynamicForm>
                        <h2>Task History</h2>
                        <JSONTableEditor
                            procedureDetails={procedureDetailsCompletion}
                            onSave={handleSavePhaseSaveEdit}
                            sortOrder={{
                                'date_complete': 'desc'
                            }} >
                        </JSONTableEditor>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={PunchDialogOpen} onClose={() => closePunchDialog()}>
                <DialogContent >
                    <>
                        <h2>{phaseName} Punch</h2>
                        <LotFilter onLotChange={handleLotSelected} /><br />
                        <Button variant="contained" type="submit" color="primary" onClick={handleAddPunchClick} disabled={punchLotOid == null} >Add Punch</Button>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closePunchDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog fullWidth={true} maxWidth='lg' open={UndergroundDialogOpen} onClose={() => setUndergroundDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>Tickets</h2>
                        <JSONTableEditor
                            key={refreshUndergroundKey + 1}
                            procedureDetails={procedureDetailsUndergroundHistory}
                            onSave={handleSaveUndergroundHistory}
                            allowDelete={true}
                            sortOrder={{
                                'oid': 'desc'
                            }} >
                        </JSONTableEditor>
                        <h2>New Ticket
                        </h2>
                        <DynamicForm
                            key={refreshUndergroundKey}
                            procedureDetails={procedureDetailsUndergroundTickets}
                            onSave={handleCompleteUndergroundClose}
                            saveName='Insert'
                            crudType='insert' ></DynamicForm>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUndergroundDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog fullWidth={true} maxWidth='lg' open={addQCDialogOpen} onClose={() => setAddQCDialogOpen(false)}>
                <DialogTitle><h1>{qcTitle}</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={confirmLotQCAddOption}></LotFilter>
                    {isInspection === 1 && (
                        <Select style={{ marginLeft: '25px' }}
                            onChange={handleSelectedSecondaryPhaseForQCChange}
                            value={selectedSecondaryPhaseForQC}>
                            <MenuItem key={'confirm_11'} value={'slab_inspection'}>
                                Slab Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_12'} value={'underground_inspection'}>
                                Underground Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_13'} value={'rough_inspection'}>
                                Rough Inspection
                            </MenuItem>
                            <MenuItem key={'confirm_14'} value={'setout_inspection'}>
                                Setout Inspection
                            </MenuItem>
                        </Select>
                    )}

                    <Select style={{ marginLeft: '25px' }}
                        onChange={handleSelectedPhaseForQCChange}
                        value={selectedPhaseForQC}>
                        <MenuItem key={-1} value={0}>
                            SELECT
                        </MenuItem>
                        {qcPhaseOptions &&
                            qcPhaseOptions.slice().sort((a, b) => a.phase_type.localeCompare(b.phase_type)) // Sort Builders inline
                                .map((phase) => (
                                    <MenuItem key={phase.oid} value={phase.oid}>
                                        {phase.phase_type + '  '}  {phase.date_scheduled !== null && (new Date(phase.date_scheduled).toISOString().split('T')[0])}
                                    </MenuItem>
                                ))}
                    </Select>
                    <br />
                    <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={AddQCValid === false || AddQCValid === null || selectedPhaseForQC === null || selectedPhaseForQC === 0} onClick={() => insertNewQC()}  >Insert</Button>
                    {/* {AddQCValid === false && (<><br /><label style={{ color: 'red' }}>Existing Quality Check For This Lot</label></>)} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAddQCDialogOpen(false); setQcPhaseOptions([], selectedSecondaryPhaseForQC); }} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
    </>
  );
};
export default BasePhase;