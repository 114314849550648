import React, { useState, useEffect, useMemo } from 'react';
import CentralCalls from '../../centralCalls';
import { makeStyles, TextField, FormControl, IconButton, Checkbox } from '@material-ui/core';
import WipLink from '../WipLink';
import { useNavigate } from 'react-router-dom';
import PhaseLink from '../PhaseLink';
import Autocomplete from '@mui/material/Autocomplete';
import JSONTableEditor from '../JSONTableEditor';
import { setHours } from 'date-fns';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from 'recharts';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    customDivider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#e0e0e0',  // Adjust the color as needed
        margin: theme.spacing(2, 0),  // Adjust for desired spacing above and below
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    datePickerContainer: {
        display: 'flex',
        alignItems: 'center', // To align the items vertically in the center
        gap: theme.spacing(1)  // Spacing between the text and the input
    },
    cell: {
        border: '1px solid white',
    },
    verticallyCentered: {
        verticalAlign: 'middle',
    },
    evenRow: {
        backgroundColor: '#f2f2f2',
        '& td': {
            border: '1px solid #f2f2f2',
        },
    },
    tableHeader: {
        textAlign: 'left',  // Apply left alignment for headers
    }
}));

function PieceWork({ page_type }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleData, setScheduleData] = useState({});
    const [rangeValue, setRangeValue] = useState([null, null]);
    const [selectedPlumbers, setSelectedPlumbers] = useState([]);
    const [plumberList, setPlumberList] = useState([]);
    const [myParams, setMyParams] = useState({});
    const [totalCost, setTotalCost] = useState(0);
    const [totalHours, setTotalHours] = useState(0);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };
    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('employee').then(response => {
            const sortedResponse = response.slice().sort((a, b) => a.name.localeCompare(b.name));
            setPlumberList(sortedResponse);
        });
    }, []);
    const getTotals = async () => {

        if (startDate && endDate && selectedPlumbers.length > 0) {
            //console.log({ employee_oids: selectedPlumbers.map(item => item.oid), start_date: CentralCalls.sqlFormatdate(startDate), end_date : CentralCalls.sqlFormatdate(endDate) });
            if(page_type === 'piece_work'){
                var tot = await CentralCalls.callStoredProcedureWithParams('sp_calcPieceWorkTotal', { employee_oids: selectedPlumbers.map(item => item.oid), start_date: CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) });
                setTotalCost(tot[0].cost ? tot[0].cost : 0);
                setTotalHours(tot[0].hours ? tot[0].hours : 0);
            }
            else{
                var tot2 = await CentralCalls.callStoredProcedureWithParams('sp_calcScheduleTotal', { employee_oids: selectedPlumbers.map(item => item.oid), start_date: CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) });
                setTotalCost(tot2[0].cost ? tot2[0].cost : 0);
                setTotalHours(tot2[0].hours ? tot2[0].hours : 0);
            }
        }
    }
    useEffect(() => {
        getTotals();
    }, [selectedPlumbers, startDate, endDate]);

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
        if (endDate !== null && selectedPlumbers.length > 0) {
            //fetchScheduleData(endDate);

        }
    };
    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
        if (startDate !== null && selectedPlumbers.length > 0) {
            //fetchScheduleData(endDate);
        }
    };
    const handleSelectedPlumberChange = (newValue) => {
        setSelectedPlumbers(newValue);
    }
    const handlePostSavePieceWorkData = (row) => {
        getTotals()
    }
    const handlePostSaveScheduleData = (row) => {
        getTotals();
    }
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        window.open(path, "_blank");
    }
    const sendToPrint = async () => {
        const qry = page_type === 'piece_work' ? 'ui_sp_schedulePieceWork' : 'ui_sp_scheduleTable';
        const urlParams = 'qry=' + qry + '&employee_oids=' + selectedPlumbers.map(item => item.oid) + '&start_date=' + CentralCalls.sqlFormatdate(startDate) + '&end_date=' + CentralCalls.sqlFormatdate(endDate);
        const path = '/printSchedule?' + urlParams;
        window.open(path, "_blank");
    }
    const classes = useStyles();
    const customFunctions = [
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
        }
    ]
    const procedureDetailsPieceWorkData = useMemo(() => ({
        procedureName: 'ui_sp_schedulePieceWork',
        parameters: {
            employee_oids: selectedPlumbers.map(item => item.oid),
            start_date: CentralCalls.sqlFormatdate(startDate),
            end_date: CentralCalls.sqlFormatdate(endDate)
        }
    }), [selectedPlumbers, startDate, endDate]);
    const handleSelectAll = () => {
        // If "Select All" is clicked, set the selectedPlumbers to the entire list of options.
        setSelectedPlumbers(plumberList);
        console.log('select all')
    };
    const procedureDetailsScheduleData = {
        procedureName: 'ui_sp_scheduleTable',
        parameters: { employee_oids: selectedPlumbers.map(item => item.oid), start_date: CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },  // If there are any parameters, define them here as an object
    };
    return (
        <div className={classes.root}>
            {page_type === 'piece_work' ? (
                <h1>Piece Work</h1>
            ) : (<h1>Schedule</h1>)}
            <div className={classes.datePickerContainer}>
                <h4>Select All</h4>
                <Label>Select All</Label>
                <Checkbox checked={isSelectAll}
                                
                                onChange={(event, newValue) => {
                                    if (newValue === true) {
                                        setIsSelectAll(true);
                                        handleSelectAll(); // If "Select All" is checked, select all options
                                     } else {
                                         setSelectedPlumbers([]);
                                         setIsSelectAll(false);
                                          // Update the selectedPlumbers state
                                     }
                                }}
                            />
                <Autocomplete
                    disabled={isSelectAll}
                    options={plumberList.sort((a, b) => a.name.localeCompare(b.name)) || []}
                    getOptionLabel={(option) => option.name}
                    multiple={true}
                    style={{ width: '350px' }}
                    onChange={(event, newValue) => {
                        handleSelectedPlumberChange(newValue); // Update the selectedPlumbers state
                    }}
                    renderInput={(params) => (
                        <FormControl fullWidth required={true}>
                            <TextField {...params} variant="standard" required={true} />
                        </FormControl>
                    )}
                />

                <h4>Select Date Range</h4>
                <input type="date"
                    onChange={(e) => { handleStartDateChange(new Date(e.target.value)) }} />
                -
                <input type="date"
                    onChange={(e) => {
                        handleEndDateChange(new Date(e.target.value));
                    }} />
                <IconButton
                    disabled={selectedPlumbers && selectedPlumbers?.length > 0 && startDate && endDate ? false : true}
                    color="primary"
                    aria-label="save"
                    onClick={() => { sendToPrint() }}
                    size="medium"
                >
                    <FontAwesomeIcon icon={faPrint} />
                </IconButton>
            </div>
            <div className={classes.customDivider}></div>

            {page_type === 'piece_work' ? (
                <>
                    <h3>Total Cost: {CentralCalls.formatTotal(totalCost)}</h3>
                    <JSONTableEditor
                        procedureDetails={procedureDetailsPieceWorkData}
                        onSave={handlePostSavePieceWorkData}
                        customFunctions={customFunctions}
                        sortOrder={{
                            'date_complete': 'asc'
                        }} ></JSONTableEditor>
                </>
            ) : (
                <><h3>Total Hours: {totalHours}</h3>
                    <JSONTableEditor
                        procedureDetails={procedureDetailsScheduleData}
                        onSave={handlePostSaveScheduleData}
                        customFunctions={customFunctions}
                        sortOrder={{
                            'date_complete': 'asc'
                        }} ></JSONTableEditor>
                </>
            )}

            {/* <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.tableHeader}>Employees</th>
                        <th className={classes.tableHeader}>Builder</th>
                        <th className={classes.tableHeader}>Neighborhood</th>
                        <th className={classes.tableHeader}>Lot Num</th>
                        <th className={classes.tableHeader}>Phase Type</th>
                        <th className={classes.tableHeader}>Comments</th>
                        <th className={classes.tableHeader}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(scheduleData).map((employeeNames, idx) => {
                        const phases = scheduleData[employeeNames];
                        return (
                            <React.Fragment key={idx}>
                                <tr className={idx % 2 === 0 ? classes.evenRow : ''}>
                                    <td rowSpan={phases.length} className={`${classes.verticallyCentered} ${classes.cell}`}>{employeeNames}</td>
                                    <td className={classes.cell}>{phases[0].builder}</td>
                                    <td className={classes.cell}>{phases[0].neighborhood}</td>
                                    <td className={classes.cell}>{phases[0].lot_num}</td>
                                    <td className={classes.cell}>{phases[0].phase_type}</td>
                                    <td className={classes.cell}>{phases[0].comments}</td>
                                    <td><WipLink lot_oid={phases[0].lot_oid} ></WipLink>
                                        <PhaseLink phase_type={phases[0].phase_type_id} ></PhaseLink></td>
                                </tr>
                                {phases.slice(1).map((phase, innerIdx) => (
                                    <tr key={innerIdx} className={idx % 2 === 0 ? classes.evenRow : ''}>

                                        <td className={classes.cell}>{phase.builder}</td>
                                        <td className={classes.cell}>{phase.neighborhood}</td>
                                        <td className={classes.cell}>{phase.lot_num}</td>
                                        <td className={classes.cell}>{phase.phase_type}</td>
                                        <td className={classes.cell}>{phase.comments}</td>
                                        <td><WipLink lot_oid={phase.lot_oid} ></WipLink>
                                            <PhaseLink phase_type={phase.phase_type_id} ></PhaseLink></td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table> */}
        </div>
    );
}

export default PieceWork;
