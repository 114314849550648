import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, makeStyles, Collapse, ListItemIcon, Grid, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import FaucetIcon from '@material-ui/icons/LocalDrink';
import DrainIcon from '@material-ui/icons/CallToAction';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BathtubIcon from '@material-ui/icons/Bathtub';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import NoteIcon from '@material-ui/icons/Note';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ReportIcon2 from '@material-ui/icons/Assessment';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
//import reportIcon3 from '@material-ui/icons/Summarize';
import EmployeeIcon from '@material-ui/icons/SupervisedUserCircle';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import AdminIcon from '@material-ui/icons/SupervisedUserCircle';
import BuildersIcon from '@material-ui/icons/Group';
import BidsIcon from '@material-ui/icons/MonetizationOn';
import MaterialsIcon from '@material-ui/icons/Category';
import JobsIcon from '@material-ui/icons/Work';
import ReportIcon from '@material-ui/icons/Report';
import LotIcon from '@material-ui/icons/LocationOn';
import FolderIcon from '@material-ui/icons/Folder';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import NeighborhoodIcon from '@material-ui/icons/LocationCity';
import EditIcon from '@material-ui/icons/Edit';
import InsertIcon from '@material-ui/icons/Add';
import SlabIcon from '@material-ui/icons/Dns';
import LocationCityIcon from '@material-ui/icons/Business';
import LandscapeIcon from '@material-ui/icons/Landscape';
import PersonIcon from '@material-ui/icons/Person';
import SewerIcon from '@material-ui/icons/Opacity';
import logo from '../logo.png';
import PhaseEdit from './phase/phase';
import GenericEdit from './GenericEdit';
import GenericInsert from './GenericInsert';
import LocationOnIcon from '@material-ui/icons/LocationOn';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#B3CDE6',
    color: 'black'
  },
  drawer: {
    width: 240,
  },
  drawerPaper: {
    width: 240,
    paddingTop: 64,
  },
  content: {
    flexGrow: 1,
    marginLeft: 240,
    padding: 16,
    marginTop: 60
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(6),
  },
  sideMenu: {
    backgroundColor: '#E5F0F9'
  }
}));
const SideMenu = ({ sideMenuOpen, setSideMenuOpen }) => {
  const classes = useStyles();
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [scheduleOpen, setScheduleOpen] = React.useState(false);
  const [phaseOpen, setPhaseOpen] = React.useState(false);
  const [reportsOpen, setReportsOpen] = React.useState(false);
  const [SlabOpen, setSlabOpen] = React.useState(false);
  const [RoughOpen, setRoughOpen] = React.useState(false);
  const [SetoutOpen, setSetoutOpen] = React.useState(false);
  const [jobsOpen, setJobsOpen] = React.useState(false);
  const [bidHover, setBidHover] = React.useState(false);
  const navigate = useNavigate();
  const access_level = localStorage.getItem('access');
  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleClickAdmin = () => {
    setAdminOpen(!adminOpen);
  };
  const handleClickSchedule = () => {
    setScheduleOpen(!scheduleOpen);
  }
  const handleClickPhase = () => {
    setPhaseOpen(!phaseOpen);
  }
  const handleClickReports = () => {
    setReportsOpen(!reportsOpen);
  }
  const handleClickSlab = () => {
    setSlabOpen(!SlabOpen);
  }
  const handleClickRough = () => {
    setRoughOpen(!RoughOpen);
  }
  const handleClickSetout = () => {
    setSetoutOpen(!SetoutOpen);
  }
  // const handleClickJobs = () => {
  //   setJobsOpen(!jobsOpen);
  // };
  const handleMouseEnter = () => {
    setBidHover(true);
  };
  const handleMouseLeave = () => {
    setBidHover(false);
  };
  return (
    <Drawer
      variant="persistent"
      open={sideMenuOpen}
      classes={{ paper: `${classes.drawerPaper} ${classes.sideMenu}`, }} >
      <List>

      <ListItem button  onClick={() => handleNavigation('/about')}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItem>

      <ListItem button  onClick={() => handleNavigation('/phase/lot')}>
              <ListItemIcon>
                <LotIcon />
              </ListItemIcon>
              <ListItemText primary="New Lot" />
            </ListItem>
        <ListItem button onClick={() => handleNavigation('/wip')}>
          <ListItemIcon>
            <BuildersIcon />
          </ListItemIcon>
          <ListItemText primary="Work In Progress" />
        </ListItem>


            
            {/* <ListItem button  onClick={() => handleNavigation('/phase/warranty')}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Warranty" />
            </ListItem> */}

            <ListItem button onClick={handleClickPhase}>
          <ListItemIcon>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText primary="Operation" />
          {phaseOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={phaseOpen} timeout="auto" unmountOnExit>
          <List component="div">
           
          <ListItem button className={classes.nested} onClick={() => handleNavigation('/phase')}>
              <ListItemIcon>
                <SewerIcon />
              </ListItemIcon>
              <ListItemText primary="Phases" />
            </ListItem>
            
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/phase/permit')}>
              <ListItemIcon>
                <NoteIcon />
              </ListItemIcon>
              <ListItemText primary="Permit" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/phase/po')}>
              <ListItemIcon>
                <BidsIcon />
              </ListItemIcon>
              <ListItemText primary="PO" />
            </ListItem>

          </List>
        </Collapse>

        {/* <ListItem button onClick={handleClickSchedule}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Schedule" />
          {scheduleOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={scheduleOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/dailySchedule')}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="Daily" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/employeeSchedule')}>
              <ListItemIcon>
                <EmployeeIcon />
              </ListItemIcon>
              <ListItemText primary="Employee" />
            </ListItem>
          </List>
        </Collapse> */}

        <ListItem button onClick={() => handleNavigation('/schedule')}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="Schedule" />
            </ListItem>
        <ListItem button  onClick={() => handleNavigation('/piece_work')}>
              <ListItemIcon>
                <FaucetIcon />
              </ListItemIcon>
              <ListItemText primary="Piece Work" />
            </ListItem>
            {access_level === 'full' && (
              <>
        <ListItem button onClick={handleClickAdmin}>
          <ListItemIcon>
            <AdminIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
          {adminOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={adminOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

          <ListItem button className={classes.nested} onClick={() => handleNavigation('/export')}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary="System Export" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/neighborhood')}>
              <ListItemIcon>
                <NeighborhoodIcon />
              </ListItemIcon>
              <ListItemText primary="Neighborhoods" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_template')}>
              <ListItemIcon>
                <LotIcon />
              </ListItemIcon>
              <ListItemText primary="Material Preset" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_model')}>
              <ListItemIcon>
                <MaterialsIcon />
              </ListItemIcon>
              <ListItemText primary="Material" />
            </ListItem> 

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_templates')}>
              <ListItemIcon>
                <JobsIcon />
              </ListItemIcon>
              <ListItemText primary="Material Template" />
            </ListItem>
            {/* <ListItem button className={classes.nested} onClick={() => handleNavigation('/bid')}>
              <ListItemIcon>
                <BidsIcon />
              </ListItemIcon>
              <ListItemText primary="Bids" />
            </ListItem> */}
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/district')}>
              <ListItemIcon>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText primary="District" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/municipality')}>
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Municipality" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/builder')}>
              <ListItemIcon>
                <BuildersIcon />
              </ListItemIcon>
              <ListItemText primary="Builders" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/plan_number')}>
              <ListItemIcon>
                <NoteIcon />
              </ListItemIcon>
              <ListItemText primary="Plan Numbers" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/employee')}>
              <ListItemIcon>
                <EmployeeIcon />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/material_room')}>
              <ListItemIcon>
                <NeighborhoodIcon />
              </ListItemIcon>
              <ListItemText primary="Rooms" />
            </ListItem>

          </List>
        </Collapse>
        </>
            )}

        <ListItem button onClick={handleClickReports}>
          <ListItemIcon>
            <ReportIcon2 />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {reportsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => handleNavigation('/charts')}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => handleNavigation('/tasklist')}>
          <ListItemIcon>
            <WorkOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Work Items" />
        </ListItem>
      </List>

    </Drawer>
  );
};
export default SideMenu;