import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem, Tooltip, IconButton } from '@material-ui/core';
import GenericInsert from '../GenericInsert';
import CentralCalls from '../../centralCalls';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles.css';
const Lot = () => {
    const [SelectedBuilderOid, setSelectedBuilderOid] = useState(0);
    const [Builders, setBuilders] = useState(null);
    const [openBuilder, setOpenBuilder] = useState(false);
    useEffect(() => {
        CentralCalls.fetchTableDataNoWhere('builder').then(response => setBuilders(response));
    }, []);
    const handleAutoAddPos = async (lotRow) => {

        if (lotRow.ignore_jobs === undefined || lotRow.ignore_jobs === 0 || lotRow.ignore_jobs === '' || lotRow.ignore_jobs === false) {
            const slabRow = await CentralCalls.fetchTableData('phase', { 'lot_oid': lotRow.oid, 'phase_type': 'slab' });
            const roughRow = await CentralCalls.fetchTableData('phase', { 'lot_oid': lotRow.oid, 'phase_type': 'rough' });
            const setoutRow = await CentralCalls.fetchTableData('phase', { 'lot_oid': lotRow.oid, 'phase_type': 'setout' });
            CentralCalls.upsertData({ 'phase_type': 'slab', 'phase_oid': slabRow[0].oid, 'po_number': lotRow.slab_po_number, 'price': lotRow.slab_price }, 'po', 'insert');
            CentralCalls.upsertData({ 'phase_type': 'rough', 'phase_oid': roughRow[0].oid, 'po_number': lotRow.rough_po_number, 'price': lotRow.rough_price }, 'po', 'insert');
            CentralCalls.upsertData({ 'phase_type': 'setout', 'phase_oid': setoutRow[0].oid, 'po_number': lotRow.setout_po_number, 'price': lotRow.setout_price }, 'po', 'insert');
        }
    }
    const handleAddBuilderClickPostRun = async (builderRow) => {
        if (Builders) {
            const updatedBuilders = [...Builders, {oid : builderRow.oid, name : builderRow.name}];
            setBuilders(updatedBuilders);
          }
        setOpenBuilder(false);
        setSelectedBuilderOid(builderRow.oid);
    }
    const handlePlanNumberInsert = () => {

    }
    const handleBuilderChange = (event) => {
        setSelectedBuilderOid(event.target.value);
    };
    // use React.useState and other hooks here if necessary
    const customFunctions = [

    ]
    const procedureDetails = {
        procedureName: 'ui_sp_getLotPageInsert',
        parameters: { 'builder_oid': SelectedBuilderOid, 'lot_oid': 0 },  // If there are any parameters, define them here as an object
    };
    return (
        <>
            <div>
                <h1>New Lot</h1>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3>Builder:
                                </h3>
                            </td>
                            <td style={{ paddingRight: '20px', paddingLeft: '10px' }}>
                                <Select
                                    onChange={handleBuilderChange}
                                    value={SelectedBuilderOid ? SelectedBuilderOid : 0}>
                                    <MenuItem key={-1} value={0}>
                                        SELECT
                                    </MenuItem>
                                    {Builders &&
                                        Builders
                                            .slice()
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort Builders inline
                                            .map((builder) => (
                                                <MenuItem key={builder.oid} value={builder.oid}>
                                                    {builder.name}
                                                </MenuItem>
                                            ))}
                                </Select>
                                <Tooltip title="Add Builder">
                                    <IconButton color="primary" aria-label="save" onClick={() => setOpenBuilder(true)} size="medium">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </IconButton>
                                </Tooltip>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div className="divider"></div>
                {SelectedBuilderOid !== 0 &&
                    <DynamicForm
                        procedureDetails={procedureDetails}
                        onSave={handleAutoAddPos}
                        saveName='Insert'
                        crudType='insert'
                    />
                }
            </div>
            {openBuilder && (
                <Dialog fullWidth={true} maxWidth='lg' open={openBuilder} onClose={() => setOpenBuilder(false)}>
                    <DialogContent>
                        <GenericInsert key={'/builderIn'} displayName={'Builder'} tableName={'builder'} onInsert={handleAddBuilderClickPostRun} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenBuilder(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {/* <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogContent >
                    <>
                        <GenericInsert key={'/plan_numberIn'} tableName={'plan_number'} onInsert={handlePlanNumberInsert} />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
};

export default Lot;
