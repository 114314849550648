import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import LotFilter from '../workInProgress/LotFilter';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CentralCalls from '../../centralCalls';
const Permit = () => {
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const [AddPermitDialogOpen, setAddPermitDialogOpen] = useState(false);
    const [SelectedPermitOid, setSelectedPermitOid] = useState(0);
    const [SelectedLotOid, setSelectedLotOid] = useState(0);
    const [AddPermitValid, setAddPermitValid] = useState(null);
    const [NewPermitLotOid, setNewPermitLotOid] = useState(0);
    const [lotTitle, setLotTitle] = useState('no title');
    const [refreshKeyOpen, setRefreshKeyOpen] = useState(500);
    const [refreshKeyComplete, setRefreshKeyComplete] = useState(2000);
    const [refreshKeyDelete, setRefreshKeyDelete] = useState(3000);
    const navigate = useNavigate();
    useEffect(() => {
        const getLotTitle = async () => {
          const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', {lot_oid : SelectedLotOid });
          setLotTitle(title.title);
        }
        getLotTitle();
      }, [SelectedLotOid]);
    //const [SelectedPermitOid, setSelectedPermitOid] = useState(0);
    const handleCompletePermit = async (slabRow) => {
        setStatusDialogOpen(false);
        setRefreshKeyOpen(refreshKeyOpen +1);
        setRefreshKeyComplete(refreshKeyComplete +1);
    };
    const handleSavePermit = async (slabRow) => {
        setSelectedLotOid(slabRow.lot_oid);
        setSelectedPermitOid(slabRow.oid);
        setRefreshKeyOpen(refreshKeyOpen +1);
        setRefreshKeyComplete(refreshKeyComplete +1);
    };
    const handleSaveDelete = async (slabRow) => {
        // setSelectedLotOid(slabRow.lot_oid);
        // setSelectedPermitOid(slabRow.oid);
        setRefreshKeyDelete(refreshKeyDelete +1);

    };
    const handleOpenCompletePermit = async (slabRow) => {
        setStatusDialogOpen(true);
        setSelectedLotOid(slabRow.lot_oid);
        setSelectedPermitOid(slabRow.oid);
    }
    const checkDisplayComplete = (permitRow) => {
        if (permitRow.date_applied && permitRow.date_applied !== null) { return true; } else { return false; }
    }
    const undeletedRow = (deletedRow) => {
        CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
        setRefreshKeyOpen(refreshKeyOpen +1);
        setRefreshKeyDelete(refreshKeyDelete +1);
    }
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
    }
    const handleRevertCompletePermit = async (permitRow) => {
        CentralCalls.upsertData({ oid: permitRow.oid, complete: 0, date_permited: null }, 'permit', 'update');
        setRefreshKeyComplete(refreshKeyComplete +1);
        setRefreshKeyOpen(refreshKeyOpen +1);
    }
    const confirmLotPermitAddOption = async (lot) => {
        const result = await CentralCalls.fetchTableData('permit', { lot_oid: lot });       
        if (!result || result.length === 0) {
            //CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
            setAddPermitValid(true);
            setNewPermitLotOid(lot);
        }else{
            setAddPermitValid(false);
        }        
    }
    const insertNewPermit = async () => {
        await CentralCalls.upsertData({lot_oid : NewPermitLotOid}, 'permit','insert');
        setAddPermitDialogOpen(false);
        setRefreshKeyOpen(refreshKeyOpen +1);
    }
    // use React.useState and other hooks here if necessary
    const customFunctions = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenCompletePermit,
            displayCheck: checkDisplayComplete
        },
        {
          display_name: 'WIP',
          type: 'overview',
          func: handleWipLinkClick
        }
    ]
    const customDeleteFunctions = [
        {
            display_name: 'Revert',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: undeletedRow
        }
    ]
    
    const procedureDetails = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'complete': 0 },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsCompletion = {
        procedureName: 'ui_sp_getPermitCompletionPageData',
        parameters: { 'lot_oid': SelectedLotOid, 'permit_oid': SelectedPermitOid },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsRecent = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'complete': 1 },  // If there are any parameters, define them here as an object
    };
    const procedureDeleteDetails = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'deleted': 1 },  // If there are any parameters, define them here as an object
    };
    const customFunctionsCompletion = [
        {
            display_name: 'Undo',
            type: 'revert', // type must correspond to a FontAwesome icon name
            func: handleRevertCompletePermit
        }
    ]
    return (
        <div>
            <h1>Permits
                <Tooltip title="Add PO">
                    <IconButton color="primary" aria-label="save" onClick={() =>  {setAddPermitDialogOpen(true)}} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h1>
            <JSONTableEditor key={refreshKeyOpen}
                procedureDetails={procedureDetails}
                onSave={handleSavePermit}
                allowDelete={true}
                sortOrder={{
                    'municipality': 'asc',
                    'builder': 'asc',
                    'neighborhood': 'asc',
                    'lot': 'asc'
                }}
                customFunctions={customFunctions}
                 />
            <h1>Recent Complete Permits</h1>
            <JSONTableEditor key={refreshKeyComplete}
                procedureDetails={procedureDetailsRecent}
                customFunctions={customFunctionsCompletion}
                onSave={handleSavePermit}
                sortOrder={{
                    'municipality': 'asc',
                    'builder': 'asc',
                    'neighborhood': 'asc',
                    'lot': 'asc'
                }}
                 />
              <h1>Recent Deleted Permits</h1>
            <JSONTableEditor key={refreshKeyDelete}
                procedureDetails={procedureDeleteDetails}
                onSave={handleSaveDelete}
                hideEdit={true}
                sortOrder={{
                    'municipality': 'asc',
                    'builder': 'asc',
                    'neighborhood': 'asc',
                    'lot': 'asc'
                }} 
                customFunctions={customDeleteFunctions}/>
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogTitle><h1>Complete Permit</h1><h4>{lotTitle}</h4></DialogTitle>
                <DialogContent >
                    <DynamicForm
                        procedureDetails={procedureDetailsCompletion}
                        onSave={handleCompletePermit}
                        saveName='Complete'

                        crudType='update' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} maxWidth='lg' open={AddPermitDialogOpen} onClose={() => setAddPermitDialogOpen(false)}>
                <DialogTitle><h1>Add Permit</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={confirmLotPermitAddOption}></LotFilter>                   
                    <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={AddPermitValid === false || AddPermitValid === null || NewPermitLotOid === null || NewPermitLotOid === 0} onClick={() => insertNewPermit()}  >Insert</Button>
                    {AddPermitValid === false && ( <><br/><label style={{ color: 'red' }}>Existing Permit For This Lot</label></>)}
                </DialogContent>
                <DialogActions>
                    <Button  onClick={() => setAddPermitDialogOpen(false)} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default Permit;
