import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import { faPlus, faPrint} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const useStyles = makeStyles((theme) => ({
    fullScreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: 'white',
        overflow: 'auto',
        margin: 0,
        padding: 0
    },
    tableHeaderCell: {
        backgroundColor: '#f4f4f4', // Set your desired background color
        fontWeight: 'bold',
        borderBottom: '1px solid #ddd', // Add a bottom border for separation
        padding: theme.spacing(1),
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1), 
    },
    table: {
        width: 'calc(100% - 40px)', // Deducting the overall table margins 
        maxWidth: '100%', 
        tableLayout: 'fixed',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableCell: {
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        paddingLeft: theme.spacing(1), 
        paddingRight: theme.spacing(1), 
    },
    row: {
        borderTop: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
    },
    lastCell: {
        borderRight: 'none', 
    }
}));

function PrintMaterials() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const handlePrint = () => {
        window.print();
    };
    const location = useLocation();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const lot_oid = parseInt(query.get('lot_oid'));
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const response = await CentralCalls.callStoredProcedureWithParams('ui_sp_materialsByLotPage', { lot_oid: lot_oid });
                console.log(response);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }    
        fetchData();    
    }, [lot_oid]);
    console.log('printlot:'+ lot_oid);
    return (
        <div className={classes.fullScreen}>
            <IconButton style={{float:'right'}} color="primary" aria-label="save" onClick={() =>  {handlePrint()}} size="medium">
                        <FontAwesomeIcon icon={faPrint} />
            </IconButton>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.row} >    
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Room</TableCell>                   
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Fixture</TableCell>
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Description</TableCell>
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Model</TableCell>
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Quantity</TableCell>
                        <TableCell style={{fontWeight:'bold'}} className={classes.tableHeaderCell}>Cost</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <>
                        <TableRow key={row.lot_num}>
                            <TableCell className={classes.tableCell}>{row.room}</TableCell>
                            <TableCell className={classes.tableCell}>{row.material_type}</TableCell>                            
                            <TableCell className={classes.tableCell}>{row.material_desc}</TableCell>
                            <TableCell className={classes.tableCell}>{row.material_model}</TableCell>
                            <TableCell className={classes.tableCell}>{row.quantity}</TableCell>
                            <TableCell className={classes.tableCell}>{row.cost}</TableCell>
                        </TableRow>
                       
                        </>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
export default PrintMaterials;
