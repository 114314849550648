import React, { useState } from 'react';
import LotView from './LotView'; // Importing LotView from the same folder
import WipTabs from './WipTabs'; // Importing WipTabs from the same folder
import LotFilter from './LotFilter';
import CentralCalls from '../../centralCalls';
import { ContactsOutlined, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip, Select, MenuItem, FormControl, TextField, Divider } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const WipPage = () => {
    const [SelectedLot, setSelectedLot] = useState(0);
    //const [newPoPhases, setnewPoPhases] = useState(null);
    // You can add state and methods here if needed
    const handleLotChange = async (lot_oid) => {
        console.log('lot... ' + lot_oid)
        setSelectedLot(Number(lot_oid));
        //const rows = await CentralCalls.fetchTableData('vw_phase_stat', { 'lot_oid': lot_oid });
        //setnewPoPhases(rows);
      };
    

    return (
        <div>
             <h1>Work In Progress
                <IconButton color="primary" aria-label="save" onClick={() => { window.location.href = '/wip?lot_oid=' + SelectedLot; }} disabled={!SelectedLot} size="medium">
                    <FontAwesomeIcon icon={faRefresh} />
                </IconButton>
            </h1>
            <h2>Select Lot</h2>
            <LotFilter onLotChange={handleLotChange} /><br/>
            {/* <LotView /> */}
            <WipTabs selectedLotOid={SelectedLot} />
            {/* You can add more JSX elements or components here as needed */}
        </div>
    );
}

export default WipPage;
