
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { faPlus, faRefresh, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
// Define the component and destructure the prop from the component's props
const WipInspectionSection = ({ selectedLotOid }) => {
    const onInspectionUpdate = (obj) => { }
    const InspectionProcedureDetails = useMemo(() => ({
        procedureName: ' ui_sp_wipInspections',
        parameters: { 'lot_oid': selectedLotOid },  // If there are any parameters, define them here as an object
      }), [selectedLotOid]);
    return (
        <div>
            <h2>Inspections</h2>
            <JSONTableEditor
                procedureDetails={InspectionProcedureDetails}
                onsave={onInspectionUpdate}
                sortOrder={{
                    'is_cancelled': 'asc',
                    'status_date': 'asc',
                    'phase_order': 'asc',

                }} />
        </div>
    );
}

export default WipInspectionSection;